import axios from 'axios';
import { genQueryFromObj } from '../../Utils/Url';
import { arrayBufferToBase64 } from '../../Utils/Image';
export default {
    state: {
        dguard: [],
        snapImport: null,
        digifort: [],
        loadingCams: false,
    },
    mutations: {
        setDguardCameras(state, payload) {
            state.dguard = payload;
        },
        setLoadingCams(state, payload) {
            state.loadingCams = payload;
        },
        setDigifortCameras(state, payload) {
            state.digifort = payload;
        },
        setSnapImport(state, payload) {
            state.snapImport = payload;
        },
    },
    actions: {
        setDguardCameras(context, payload) {
            context.commit('setDguardCameras', payload);
        },
        setLoadingCams(context, payload) {
            context.commit('setLoadingCams', payload);
        },
        setDigifortCameras(context, payload) {
            context.commit('setDigifortCameras', payload);
        },
        setSnapImport(context, payload) {
            context.commit('setSnapImport', payload);
        },
        loadCamerasFromDguard(context, payload) {
            axios(`dguard/cameras?clientId=${payload}`).then((resp) => {
                context.commit('setDguardCameras', resp.data);
            });
        },
        loadCamerasFromDigifort(context, payload) {
            context.commit('setLoadingCams', true);
            axios(`digifort/cameras?clientId=${payload}`).then((resp) => {
                context.commit('setDigifortCameras', resp.data);
                context.commit('setLoadingCams', false);
            });
        },
        getCameraUrlDguard(context, payload) {
            let query = genQueryFromObj(payload);
            axios(`dguard/cameraUrl${query}`).then((resp) => {
                if (resp.status < 399) {
                    if (typeof payload.cb == 'function') {
                        payload.cb(resp.data)
                    }
                }
            });
        },
        getCameraUrlDigifort(context, payload) {
            let query = genQueryFromObj(payload);
            axios(`digifort/cameraUrl${query}`).then((resp) => {
                if (resp.status < 399) {
                    if (typeof payload.cb == 'function') {
                        payload.cb(resp.data)
                    }
                }
            });
        },
        verifyUserPassServerDguard(context, payload) {
            axios(`dguard/checkCredentials?clientId=${payload}`).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Configuração (usuário/senha/ip(s)) do servidor funcionando");
                } else {
                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", "Configuração (usuário/senha/ip(s)) do servidor não funcionando");
                }
            });
        },
        getSnapFromDguard(context, payload) {
            context.commit('setSnapImport', null);
            let query = genQueryFromObj(payload);
            axios(`dguard/snapshot${query}`, { responseType: "arraybuffer" }).then((resp) => {
                context.commit('setSnapImport', arrayBufferToBase64(resp.data, resp.headers["content-type"]));
            });
        },
        getSnapFromDigifort(context, payload) {
            context.commit('setSnapImport', null);
            let query = genQueryFromObj(payload);
            axios(`digifort/snapshot${query}`, { responseType: "arraybuffer" }).then((resp) => {
                context.commit('setSnapImport', arrayBufferToBase64(resp.data, resp.headers["content-type"]));
            });
        },
    },
    getters: {
        getCamerasDguard(state) {
            return state.dguard;
        },
        getCamerasDigifort(state) {
            return state.digifort;
        },
        getSnapImport(state) {
            return state.snapImport;
        },
        getLoadingCams(state) {
            return state.loadingCams;
        },
    },
}