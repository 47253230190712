<template>
  <v-tabs fixed-tabs background-color="primary" dark>
    <v-tab v-if="perm.getEnabled('permNav')">
      {{ perm.getText("permNav") }}
    </v-tab>
    <v-tab-item> <Permissoes /> </v-tab-item>
    <v-tab v-if="perm.getEnabled('permFNav')">
      {{ perm.getText("permFNav") }}
    </v-tab>
    <v-tab-item> <PermissoesFront /> </v-tab-item>
    <v-tab v-if="perm.getEnabled('usrMenuFNav')"
      >{{ perm.getText("usrMenuFNav") }}
    </v-tab>
    <v-tab-item> <Menu /> </v-tab-item>
    <v-tab v-if="perm.getEnabled('recNav')">
      {{ perm.getText("recNav") }}
    </v-tab>
    <v-tab-item> <Recursos /> </v-tab-item>
    <v-tab v-if="perm.getEnabled('lvlNav')">
      {{ perm.getText("lvlNav") }}
    </v-tab>
    <v-tab-item> <Niveis /> </v-tab-item>
    <v-tab v-if="perm.getEnabled('usrNav')"
      >{{ perm.getText("usrNav") }}
    </v-tab>
    <v-tab-item> <Usuarios /> </v-tab-item>
  </v-tabs>
</template>

<script>
import PermissoesHelper from "@/Utils/Permissoes";
import Recursos from "./Recursos.vue";
import Niveis from "./Niveis.vue";
import Permissoes from "./Permissoes.vue";
import Usuarios from "./Usuarios.vue";
import Menu from "./Menu.vue";
import PermissoesFront from "./PermissoesFront.vue";
export default {
  name: "AdminPage",
  data: () => {
    return {
      perm: PermissoesHelper,
    };
  },
  components: {
    Recursos,
    Niveis,
    Permissoes,
    Usuarios,
    Menu,
    PermissoesFront,
  },
  created() {},
};
</script>

<style>
</style>