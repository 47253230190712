<template>
  <v-row align="center" justify="center">
    <span class="bgL"></span>
    <v-col cols="auto">
      <v-card ref="form" max-width="400" id="cardLogin">
        <v-card-text>
          <v-card-title class="headline"> Login do Sistema </v-card-title>
          <v-text-field ref="userName" v-model="user.userName" :rules="[() => !!user.userName || 'Campo obrigatório']"
            label="Usuário" placeholder="Usuário" required></v-text-field>
          <v-text-field ref="password" v-model="user.password" :rules="[() => !!user.password || 'Campo obrigatório']"
            label="Senha" placeholder="Senha" @keydown.enter="login" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="login"> Entrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      user: {
        userName: "",
        password: "",
      },
      users: [],
    };
  },
  methods: {
    login() {
      this.$http.post(`login`, this.user).then((resp) => {
        if (resp.data) {
          let token = resp.data.token;
          this.$store.commit("setToken", token);
          this.$store.dispatch("setImgToken", resp.data.imgToken);
          this.$store.dispatch("loadRoutesConfig");
          this.$store.dispatch("loadMenu", { cb: this.pushRoute });
          // this.$store.dispatch("connectWB", token);
        }
      });
    },
    pushRoute(route) {
      // usado para redirecionar o usuário para a rota que ele tentou acessar antes de ser redirecionado para o login
      if (this.$store.getters.getTargetUrl && this.$store.getters.getTargetUrl !== "/login" && this.$store.getters.getTargetUrl !== "/") {
        return this.$router.push({ path: this.$store.getters.getTargetUrl });
      } else {
        this.$router.push({ path: route.to });
      }
    },
  },
  created() {
    let token = localStorage.getItem("heimdall");
    let imgToken = localStorage.getItem("heimdallimg");
    if (token) {
      this.$store.commit("setToken", token);
      this.$store.commit("setLogged", true);
      this.$store.dispatch("loadRoutesConfig", {});
      this.$store.dispatch("loadMenu", { cb: this.pushRoute });
      this.$store.dispatch("setImgToken", imgToken);
      // this.$store.dispatch("connectWB", token);
    } else {
      if (!this.$router.history.current.path === "/login") {
        this.$router.push({ path: "/login" });
      }
    }
  },
};
</script>

<style>
/* .bgL {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../assets/background.jpg") no-repeat center center;
  background-size: cover;

  transform: scale(1.1);
}
   */
</style>