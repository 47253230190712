<template>
  <v-app-bar app class="d-print-none topBar" dark :dense="$store.getters.getWindowSize.height < 480">
    <!-- botão para o menu caso seja tela muito pequena -->
    <v-btn v-show="$vuetify.breakpoint.xs" icon @click="$store.dispatch('toogleMenu')"><v-icon>mdi-menu</v-icon></v-btn>
    <v-toolbar-title>Heimdall STV </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y v-if="getLogged">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar class="ma-2" size="45" v-bind="attrs" v-on="on">
          <v-img
            :src="user.picture+$store.getters.getImgTokenQuery"
            :alt="user.name"
            lazy-src="@/assets/nopic.jpg"
          />
        </v-avatar>
        <span>
          {{ user.name }}
        </span>
      </template>
      <v-list>
        <v-list-item key="Trocar Senha" to="meuUsuario">
          <v-list-item-title>
            <v-icon>mdi-key-variant</v-icon> Editar Usuário
          </v-list-item-title>
        </v-list-item>
        <v-list-item key="sair" @click="logOff">
          <v-list-item-title>
            <v-icon>mdi-logout-variant</v-icon> Sair
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "AppBar",
  data: () => {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["user", "grupos", "user"]),
    ...mapGetters(["getUsuario", "getLogged"]),
    workGroup: {
      get() {
        return this.$store.state.workGroup;
      },
      set(value) {
        this.$store.dispatch("setWorkGroup", value);
      },
    },
  },
  methods: {
    ...mapActions(["logOff", "setWorkGroup"]),
  },
  // watch: {
  //   getLogged() {
  //     if (this.getLogged) {
  //       this.showMenu = true;
  //     } else {
  //       this.showMenu = false;
  //     }
  //   },
  // },
};
</script>
<style scoped>
</style>
