import axios from 'axios'
export default {
  state: {
    cameraActions: [],
  },
  mutations: {
    setCameraActions(state, payload) {
      state.cameraActions = payload
    },
  },
  actions: {
    setCameraActions(context, payload) {
      context.commit('setCameraActions', payload)
    },
    loadCameraActions(context, payload) {
      axios(`cameraaction/camera/${payload.id}`).then((resp) => {
        context.commit('setCameraActions', resp.data)
      });
    },
    saveCameraAction(context, payload) {
      const callback = payload.callback;
      delete payload.callback;
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/cameraaction${finalUrl}`, payload).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Salvo com Sucesso");
          context.dispatch('loadCameraActions', { id: payload.cameraId });
          // se tiver um callback, chama ele
          if (typeof callback == 'function') callback(resp.data);
        }
      });
    },
    runAction(context, payload) {
      axios.post(`/cameraaction/run/${payload.id}`).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Enviado para execução");
        }
      });
    },
    deletCameraAction(context, payload) {
      const callback = payload.callback;
      delete payload.callback;
      axios.delete(`/cameraaction/${payload.id}`).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Deletado com Sucesso");
          context.dispatch('loadCameraActions', { id: payload.cameraId });
          // se tiver um callback, chama ele
          if (typeof callback == 'function') callback(resp.data);
        }
      });
    },
  },
  getters: {
    getCameraActions(state) {
      return state.cameraActions
    },
  },
}