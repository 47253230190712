import axios from 'axios'
export default {
    state: {
        eventos: [],
        evento: {},
        tratamento: null,
        reports: [],
        filterReport: "",
        selectedCam: "",
        lastReport: {
            cameraId: "",
            start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
            end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
        },
        eventLoading: false,
        cleanData: []
    },
    mutations: {
        setEventos(state, payload) {
            state.eventos = payload
        },
        setCleanData(state, payload) {
            state.cleanData = payload
        },
        setSelectedCam(state, payload) {
            state.selectedCam = payload
        },
        setLastReport(state, payload) {
            state.lastReport = payload
        },
        setFilterReport(state, payload) {
            state.filterReport = payload
        },
        setEvento(state, payload) {
            state.evento = payload
        },
        setTratamento(state, payload) {
            state.tratamento = payload
        },
        setReports(state, payload) {
            state.reports = payload
        },
        setEventLoading(state, payload) {
            state.eventLoading = payload
        },
        appendEvent(state, payload) {
            state.eventos.unshift(payload)
        },
    },
    actions: {
        setEventos(context, payload) {
            context.commit('setEventos', payload)
        },
        setCleanData(context, payload) {
            context.commit('setCleanData', payload)
        },
        setSelectedCam(context, payload) {
            context.commit('setSelectedCam', payload)
        },
        setLastReport(context, payload) {
            context.commit('setLastReport', payload)
        },
        setFilterReport(context, payload) {
            context.commit('setFilterReport', payload)
        },
        setEvento(context, payload) {
            context.commit('setEvento', payload)
        },
        setTratamento(context, payload) {
            context.commit('setTratamento', payload)
        },
        setReports(context, payload) {
            context.commit('setReports', payload)
        },
        loadEventos(context) {
            console.time("loadEventos");
            context.commit('setEventLoading', true);
            axios(`eventos?date=${new Date().getTime()}`).then((resp) => {
                context.commit('setEventos', resp.data);
                console.timeEnd("loadEventos");
                context.commit('setEventLoading', false);
            });
        },
        appendEventById(context, payload) {
            console.time("loadEventoById");
            context.commit('setEventLoading', true);
            axios(`eventos/${payload}`).then((resp) => {
                context.commit('appendEvent', resp.data);
                console.timeEnd("loadEventoById");
                context.commit('setEventLoading', false);
                context.dispatch("resetSnack");
                context.dispatch("showSnack", `Novo evento: ${resp.data.evtName} - ${resp.data.cameraName}`);
                // context.commit('setEvento',  resp.data);
            });
        },
        loadReportEventos(context, payload) {
            let callback;
            if(typeof payload.callback == 'function'){
                callback = payload.callback;
                delete payload.callback;
            }
            let query = "";
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    query += `${key}=${payload[key]}&`
                }
            }
            axios(`relatorios?${query}`).then((resp) => {
                context.commit('setReports', resp.data);
                if (resp.status == 200) {
                    // console.log(resp.data);
                    if (callback) {
                        callback(resp.data);
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Relatório gerado com sucesso!");
                }
            });
        },
        loadReportEventosMultiplo(context, payload) {
            let query = "";
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    if (Array.isArray(payload[key])) {
                        payload[key].forEach((el) => {
                            query += `${key}=${el}&`
                        });
                    } else {
                        query += payload[key] ? `${key}=${payload[key]}&` : ''
                    }
                }
            }
            axios(`relatoriosMultiplo?${query}`).then((resp) => {
                context.commit('setReports', resp.data);
                if (resp.status == 200) {
                    // console.log(resp.data);
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Relatório gerado com sucesso! Eventos: " + resp.data.length);
                }
            });
        },
        loadReportEventosTratamentos(context, payload) {
            let query = "";
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    query += `${key}=${payload[key]}&`
                }
            }
            axios(`relatorios/tratamento?${query}`).then((resp) => {
                context.commit('setReports', resp.data);
                if (resp.status == 200) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Relatório com tratamentos gerado com sucesso!");
                }
            });
        },
        loadEventosByClientHash(context, hash) {
            axios(`eventos/byClientHash?hash=${hash}`).then((resp) => {
                context.commit('setEventos', resp.data)
            });
        },
        loadEventosByClient(context, payload) {
            context.commit('setEventLoading', true);
            console.time("loadEventosByClient");
            // usado neste formato para enganar o IE
            axios(`eventos/byClientHash?date=${new Date().getTime()}`).then((resp) => {
                if (payload) {
                    if (typeof payload.cb == 'function') {
                        payload.cb(resp.data)
                    }
                }
                context.commit('setEventLoading', false);
                context.commit('setEventos', resp.data);
                console.timeEnd("loadEventosByClient");
            });
        },
        loadEventosByClientId(context, payload) {
            context.commit('setEventLoading', true);
            context.commit('setEventos', []);
            console.time("loadEventosByClientId");
            axios(`eventos/client/${payload.id}`).then((resp) => {
                if (typeof payload.cb == 'function') {
                    payload.cb(resp.data)
                }
                context.commit('setEventos', resp.data);
                context.commit('setEventLoading', false);
                console.timeEnd("loadEventosByClientId");
            });
        },
        loadEventosByCameraId(context, payload) {
            context.commit('setEventLoading', true);
            context.commit('setEventos', []);
            console.time("loadEventosByCameraId");
            axios(`eventos/camera/${payload.id}`).then((resp) => {
                if (typeof payload.cb == 'function') {
                    payload.cb(resp.data)
                }
                context.commit('setEventos', resp.data);
                context.commit('setEventLoading', false);
                console.timeEnd("loadEventosByCameraId");
            });
        },
        tratamento(context, payload) {
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios.put(`/eventos/tratamento${finalUrl}`, payload).then((resp) => {
                if (resp.status < 399) {
                    if (typeof payload.cb == 'function') {
                        payload.cb()
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Evento fechado com Sucesso");
                    // context.dispatch('loadEventos')
                }
            });
        },
        tratamentoMultiplo(context, payload) {
            axios.put(`/eventos/tratamentoMultiplo`, payload).then((resp) => {
                if (resp.status < 399) {
                    if (typeof payload.cb == 'function') {
                        payload.cb()
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", payload.ids.length + " Eventos fechados com Sucesso");
                    // context.dispatch('loadEventos')
                }
            });
        },
    },
    getters: {
        getEventos(state) {
            return state.eventos
        },
        getCleanData(state) {
            return state.cleanData
        },
        getSelectedCam(state) {
            return state.selectedCam
        },
        getLastReport(state) {
            return state.lastReport
        },
        getReport(state) {
            return state.reports
        },
        getReportFilter(state) {
            let filtred = state.reports.filter((rep) => {
                return (rep.evtName ? rep.evtName.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
                    || (rep.tratamento ? rep.tratamento.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
                    || (rep.userName ? rep.userName.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
                    || (rep.monitoramentoName ? rep.monitoramentoName.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
                    || (rep.snapshot ? rep.snapshot.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
                // || (rep.createdAt ? rep.createdAt.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
                // || (rep.tratamentoCreatedAt ? rep.tratamentoCreatedAt.toLowerCase().includes(state.filterReport.toLowerCase()) : false)
            });
            return filtred
        },
        getEvento(state) {
            return state.evento
        },
        getTratamento(state) {
            return state.tratamento
        },
        getEventLoading(state) {
            return state.eventLoading
        },
    },
}