import axios from 'axios'
export default {
    state: {
        manuais: [],
    },
    mutations: {
        setManuais(state, payload) {
            state.manuais = payload
        },
    },
    actions: {
        setManuais(context, payload) {
            context.commit('setManuais', payload)
        },
        loadManuais(context) {
            axios(`manual`).then((resp) => {
                // console.log(resp.data);
                context.commit('setManuais', resp.data)
            });
        },
        saveManual(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/manual${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Manual salvo com Sucesso");
                    context.dispatch('loadManuais')
                }
            });
        },
        deletManual(context, payload) {
            axios.delete(`/manual/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Manual Deletado com Sucesso");
                context.dispatch('loadManuais');
                }
            });
        },
    },
    getters: {
        getManuais(state) {
            return state.manuais
        },
        getManualSelect: (state) =>  {
            let gp = []
            state.manuais.forEach(element => {
                gp.push({value: element.value, text: `${element.value} - ${element.text}`})
            });
            return gp 
        },
        getManualById: (state) => (id) => {
            let gp = state.manuais.find(g =>
                g.id == id
            )
            return gp 
        },
        getManualNameById: (state) => (id) => {
            if (!state.manuais) {
                return id
            }
            let gp = state.manuais.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
    },
}