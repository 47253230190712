import axios from 'axios'
export default {
    state: {
        actualRoute: [],
        allRoutes: [],
        userFront:[],
    },
    mutations: {
        setActualRoute(state, payload) {
            state.actualRoute = payload
        },
        setAllRoutes(state, payload) {
            state.allRoutes = payload
        },
        setUserFront(state, payload) {
            state.userFront = payload
        },
    },
    actions: {
        setActualRoute(context, payload) {
            context.commit('setActualRoute', payload)
        },
        setAllRoutes(context, payload) {
            context.commit('setAllRoutes', payload)
        },
        setUserFront(context, payload) {
            context.commit('setUserFront', payload)
        },
        loadRoutesConfig(context) {
            axios(`userfront/user`).then((resp) => {
                if(resp.status < 400){
                    context.dispatch('setAllRoutes', resp.data);
                }
            });
        },
        loadAllRoutes(context) {
            axios(`userfront`).then((resp) => {
                if(resp.status < 400){
                    context.dispatch('setUserFront', resp.data);
                }
            });
        },
        saveRoute(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/userfront${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Rota salvo com Sucesso");
                    context.dispatch('loadAllRoutes');
                }
            });
        },
        deleteRoute(context, payload) {
            axios.delete(`/userfront/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Grupo Deletado com Sucesso");
                context.dispatch('loadAllRoutes');
                }
            });
        },
    },
    getters: {
        getActualRoute(state) {
            return state.actualRoute;
        },
        getAllRoutes(state) {
            return state.allRoutes;
        },
        getUserFront(state) {
            return state.userFront;
        },
        getRouteByPath: (state) => (path) => {
            let gp = state.allRoutes.find(g =>
                g.path == path
            );
            if(gp){
                return gp.config;
            }
            return {};
        },
    },
}