import axios from 'axios'
export default {
    state: {
        cameras: [],
        camerasTypes: [],
        camerasStatus: [],
        agendamentosCameras: [],
        loadingCam: false,
        cameraEvents: [],
        filterCam: "",
        filterCamGroup: [],
        filterCamClient: [],
        filterCamType: [],
        filterCamActive: false,
        filterCamProxy: "",
        testPortRunning: false,
        cameraDraws: [],

    },
    mutations: {
        setCameras(state, payload) {
            state.cameras = payload
        },
        setFilterCam(state, payload) {
            state.filterCam = payload
        },
        setFilterCamGroup(state, payload) {
            state.filterCamGroup = payload
        },
        setFilterCamClient(state, payload) {
            state.filterCamClient = payload
        },
        setFilterCamModel(state, payload) {
            state.filterCamType = payload
        },
        setFilterCamActive(state, payload) {
            state.filterCamActive = payload
        },
        setFilterCamProxy(state, payload) {
            state.filterCamProxy = payload
        },
        setLoading(state, payload) {
            state.loadingCam = payload
        },
        setTestPortRunning(state, payload) {
            state.testPortRunning = payload
        },
        setCamerasTypes(state, payload) {
            state.camerasTypes = payload
        },
        setCamerasStatus(state, payload) {
            state.camerasStatus = payload
        },
        setAgendamentoCameras(state, payload) {
            state.agendamentosCameras = payload
        },
        setEventosCamera(state, payload) {
            state.cameraEvents = payload
        },
        setCameraDraws(state, payload) {
            state.cameraDraws = payload
        },
        // informa pelo websocket o status da camera
        setCameraStatus(state, payload) {
            let idx = state.camerasStatus.findIndex((cam) => cam.id == payload.cameraId);
            if (idx >= 0) {
                state.camerasStatus[idx].status = {
                    lastEvent: new Date().toISOString(),
                    conected: payload.code != "disconnected"
                };
            }
        },
    },
    actions: {
        setCameras(context, payload) {
            context.commit('setCameras', payload)
        },
        setFilterCam(context, payload) {
            context.commit('setFilterCam', payload)
        },
        setFilterCamGroup(context, payload) {
            context.commit('setFilterCamGroup', payload)
        },
        setFilterCamClient(context, payload) {
            context.commit('setFilterCamClient', payload)
        },
        setFilterCamModel(context, payload) {
            context.commit('setFilterCamModel', payload)
        },
        setFilterCamActive(context, payload) {
            context.commit('setFilterCamActive', payload)
        },
        setFilterCamProxy(context, payload) {
            context.commit('setFilterCamProxy', payload)
        },
        setLoading(context, payload) {
            context.commit('setLoading', payload)
        },
        setTestPortRunning(context, payload) {
            context.commit('setTestPortRunning', payload)
        },
        setAgendamentoCameras(context, payload) {
            context.commit('setAgendamentoCameras', payload)
        },
        setEventosCamera(context, payload) {
            context.commit('setEventosCamera', payload)
        },
        setCameraDraws(context, payload) {
            context.commit('setCameraDraws', payload)
        },
        loadCameras(context) {
            context.commit('setLoading', true)
            axios(`camera`).then((resp) => {
                // console.log(resp.data);
                context.commit('setCameras', resp.data)
                context.commit('setLoading', false)
            });
        },
        loadCameraEvent(context, payload) {
            axios(`camera/eventsAvailable/${payload.id}`).then((resp) => {
                if (typeof payload.cb == 'function') {
                    payload.cb(resp.data)
                }
                context.commit('setEventosCamera', resp.data);
            });
        },
        syncCam(context, payload) {
            axios(`camera/smartSync/${payload.id}`).then((resp) => {
                if (typeof payload.cb == 'function') {
                    payload.cb(resp.data)
                }
                context.commit('setCameraDraws', resp.data);
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", `Camera ${payload.name.toUpperCase()} sincronizada com sucesso`);
            });
        },
        loadCamerasByClientId(context, payload) {
            context.commit('setLoading', true)
            axios(`camera/client/${payload}`).then((resp) => {
                context.commit('setCameras', resp.data)
                context.commit('setLoading', false)
            });
        },
        loadAgendamentosByCameraId(context, payload) {
            axios(`agendaCameras/camera/${payload}`).then((resp) => {
                // console.log(resp.data);
                context.commit('setAgendamentoCameras', resp.data)
            });
        },
        loadCamTypes(context) {
            // if(context.state.camerasTypes.length > 0) return;
            axios(`camera/types`).then((resp) => {
                context.commit('setCamerasTypes', resp.data)
            });
        },
        loadAllCamTypes(context) {
            axios(`cameraModels`).then((resp) => {
                context.commit('setCamerasTypes', resp.data)
            });
        },
        loadCameraStatus(context) {
            axios(`camera/status`).then((resp) => {
                context.commit('setCamerasStatus', resp.data)
            });
        },
        testPort(context, payload) {
            context.dispatch("setTestPortRunning", true);
            axios.post(`testePort`, payload).then((resp) => {
                context.dispatch("resetSnack");
                if (resp.status < 399) {
                    if (resp.data.open) {
                        context.dispatch("showSuccessSnack", `Porta ${payload.port} no endereço ${payload.address} está aberta`);
                    } else {
                        context.dispatch("showErrorSnack", `Porta ${payload.port} no endereço ${payload.address} está fechada`);
                    }
                } else {
                    context.dispatch("showErrorSnack", "Erro no teste de porta");
                }
                context.dispatch("setTestPortRunning", false);
            }).catch(() => {
                context.dispatch("setTestPortRunning", false);
            });
        },
        saveCamera(context, camera) {
            const metodo = camera.id ? "put" : "post";
            const finalUrl = camera.id ? `/${camera.id}` : "";
            axios[metodo](`/camera${finalUrl}`, camera).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Camera salva com Sucesso");
                    if (typeof camera.cb == 'function') {
                        camera.cb(resp.data)
                    }
                    // if (camera.clientId) {
                    //     context.dispatch('loadCamerasByClientId', camera.clientId)
                    // } else {
                    //     context.dispatch('loadCameras')

                    // }
                }
            });
        },
        saveCameraModel(context, camera) {
            const metodo = camera.id ? "put" : "post";
            const finalUrl = camera.id ? `/${camera.id}` : "";
            axios[metodo](`/cameraModels${finalUrl}`, camera).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Modelo de câmera salvo com Sucesso");
                    context.dispatch("loadAllCamTypes");
                    if (typeof camera.cb == 'function') {
                        camera.cb(resp.data)
                    }
                }
            });
        },
        saveAgendamentoCam(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/agendaCameras${finalUrl}`, payload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Agendamento salva com Sucesso");
                    if (payload.id) {
                        context.dispatch('loadAgendamentosByCameraId', payload.id)
                    }
                    if (typeof payload.cb == 'function') {
                        payload.cb(resp.data)
                    }
                }
            });
        },
        deletCamera(context, camera) {
            axios.delete(`/camera/${camera.id}`).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Camera Deletada com Sucesso");
                    context.dispatch('loadCameras');
                }
            });
        },
        forceDetecion(context, camera) {
            console.log(`/digifort/motion?camera=${camera.hash}`);
            axios.get(`/digifort/motion?camera=${camera.hash}`).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Simulação movimento com Sucesso");
                }
            });
        },
        deletAgendamentoCam(context, payload) {
            axios.delete(`/agendaCameras/${payload.id}`).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Agendamento Deletado com Sucesso");
                    context.dispatch('loadAgendamentosByCameraId', payload.cameraId);
                }
            });
        },
        enableCamera(context, camera) {
            axios.put(`/camera/enable/${camera.id}`, { enable: camera.enable }).then((resp) => {
                if (resp.status < 399) {
                    // console.log(resp.data);
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", `Camera ${camera.enable ? 'Ativada' : 'Desativada'} com Sucesso`);
                    if (typeof camera.cb == 'function') {
                        camera.cb(resp.data)
                    }
                    // context.dispatch('loadCamerasByClientId', camera.clientId);
                }
            });
        },
    },
    getters: {
        getCameras(state) {
            return state.cameras
        },
        getCamerasFilter(state) {
            const filterModel = (arr = [], model = 0) => arr.length ? arr.includes(model) : true;
            const filterModelStr = (str = "", search = "") => str.toLowerCase().includes(search.toLowerCase());
            let cf = state.cameras;
            // filtro por tipo
            if (state.filterCamType.length) {
                cf = cf.filter((cam) => filterModel(state.filterCamType, cam.modelId));
            }
            // filtro por cliente
            if (state.filterCamClient.length) {
                cf = cf.filter((cam) => filterModel(state.filterCamClient, cam.clientId));
            }
            // filtro por grupo
            if (state.filterCamGroup.length) {
                cf = cf.filter((cam) => filterModel(state.filterCamGroup, cam.groupId));
            }
            // filtro por ativo
            if (state.filterCamActive) {
                cf = cf.filter((cam) => cam.deletedAt == null);
            }
            // filtro por proxy
            if (state.filterCamProxy) {
                if (state.filterCamProxy == "SIM") {
                    cf = cf.filter((cam) => cam.proxyId);
                } else if (state.filterCamProxy == "NÃO") {
                    cf = cf.filter((cam) => !cam.proxyId);
                }
            }
            // filtro por texto
            cf = cf.filter((cam) => {
                return filterModelStr(cam.name, state.filterCam)
                    || filterModelStr(cam.user, state.filterCam)
                    || filterModelStr(`${cam.id}`, state.filterCam)
                    || filterModelStr(cam.password, state.filterCam)
                    || filterModelStr(cam.zone, state.filterCam)
                    || filterModelStr(cam.url, state.filterCam);
            });
            return cf
        },
        getCamerasTypes(state) {
            return state.camerasTypes
        },
        getCamerasStatus(state) {
            return state.camerasStatus
        },
        getAgendamentosCameras(state) {
            return state.agendamentosCameras
        },
        getEventosCamera(state) {
            return state.cameraEvents
        },
        getCameraNameById: (state) => (id) => {
            if (!state.cameras) {
                return id
            }
            let gp = state.cameras.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getCameraOnlyNameById: (state) => (id) => {
            if (!state.cameras) {
                return id
            }
            let gp = state.cameras.find(g =>
                g.id == id
            )
            return gp ? `${gp.name}` : ''
        },
        getCameraStatusById: (state) => (id) => {
            if (!state.camerasStatus) {
                return id
            }
            let gp = state.camerasStatus.find(g =>
                g.id == id
            )
            return gp ? gp.status : {}
        },
        getCameraTypeById: (state) => (id) => {
            if (!state.camerasTypes) {
                return id
            }
            let gp = state.camerasTypes.find(g =>
                g.value == id
            )
            return gp ? `${gp.value} - ${gp.text}` : ''
        },
        getCamTypeBtnsEnabledById: (state) => (id, name) => {
            if (!state.camerasTypes) {
                return id
            }
            let gp = state.camerasTypes.find(g =>
                g.value == id
            )
            if (Array.isArray(gp.config.btns)) {
                return gp.config.btns.includes(name)
            }
            return false
        },
        getCamerasSelect: (state) => {
            let gp = []
            state.cameras.forEach(element => {
                gp.push({ value: element.id, text: `${element.id} - ${element.name}` })
            });
            return gp
        },
        getLoadingCam(state) {
            return state.loadingCam
        },
        getFilterCamModel(state) {
            return state.filterCamType
        },
        getFilterCamClient(state) {
            return state.filterCamClient
        },
        getFilterCamGroup(state) {
            return state.filterCamGroup
        },
        getFilterCamActive(state) {
            return state.filterCamActive
        },
        getFilterCamProxy(state) {
            return state.filterCamProxy
        },
        getFilterCam(state) {
            return state.filterCam
        },
        getTestPortRunning(state) {
            return state.testPortRunning
        },
    },
}