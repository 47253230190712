import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import AdminPage from '../components/Admin/AdminPage'
import UserLevels from '../components/Admin/Niveis'
import Grupo from '../components/Admin/Grupo'
import UserResources from '../components/Admin/Recursos'
import UserPermission from '../components/Admin/Permissoes'
import User from '../components/Admin/Usuarios'
import Logout from '../views/Logout'
import Login from '../views/Login'
import Home from '../views/Home'
import Request from '../views/Request'
import RouteCreator from '../views/RoutesCreator'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/adm',
        name: 'Admin',
        component: AdminPage,
        meta: { requiresAuth: true },
        available: require('./config/adm'),
    },
    {
        path: '/userlevels',
        name: 'UserLevels',
        component: UserLevels,
        meta: { requiresAuth: true }
    },
    {
        path: '/userresources',
        name: 'UserResources',
        component: UserResources,
        meta: { requiresAuth: true }
    },
    {
        path: '/userpermission',
        name: 'UserPermission',
        component: UserPermission,
        meta: { requiresAuth: true }
    },
    {
        path: '/grupo',
        name: 'Grupo',
        component: Grupo,
        meta: { requiresAuth: true }
    },
    {
        path: '/request',
        name: 'Request',
        component: Request,
        meta: { requiresAuth: true }
    },
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: { requiresAuth: true },
        available: {
            btnCancel: {
                name: "Botão Cancelar",
                text: "Cancelar",
                enabled: true
            },
            btnSave: {
                name: "Botão Salvar",
                text: "Salvar",
                enabled: true
            },
            btnRefresh: {
                name: "Botão Atualizar",
                text: "Atualizar Dados",
                enabled: true
            },
        }
    },
    {
        path: '/meuUsuario',
        name: 'MeuUsuario',
        component: () =>
            import(/* webpackChunkName: "MeuUsuario" */ "../components/Usuario/MeuUsuario.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: { requiresAuth: true }
    },
    {
        path: '/routecreator',
        name: 'RouteCreator',
        component: RouteCreator,
        meta: { requiresAuth: true }
    },
    {
        path: '/unidades',
        name: 'Unidades',
        component: () =>
            import(/* webpackChunkName: "Unidades" */ "../views/Unidades.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/cameras',
        name: 'Cameras',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Cameras" */ "../views/Cameras/Cameras.vue"),
        meta: { requiresAuth: true },
        available: require('./config/cameras'),
    },
    {
        path: '/monitoramento',
        name: 'Monitoramento',
        component: () =>
            import(/* webpackChunkName: "Monitoramento" */ "../views/Monitoramento/Monitoramento.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: () =>
            import(/* webpackChunkName: "CLientes" */ "../views/Cliente/Clientes.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/eventos',
        name: 'Eventos',
        component: () =>
            import(/* webpackChunkName: "Eventos" */ "../views/Eventos.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/configuracaoEventos',
        name: 'ConfiguracaoEventos',
        component: () =>
            import(/* webpackChunkName: "ConfiguracaoEventos" */ "../views/ConfigEventos.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/cliente',
        name: 'Cliente',
        props: true,
        component: () =>
            import(/* webpackChunkName: "ClienteEventos" */ "../views/ClienteEventos.vue"),
    },
    {
        path: '/relatorioEventos',
        name: 'RelatorioEventos',
        props: true,
        component: () =>
            import(/* webpackChunkName: "RelatorioEventos" */ "../views/RelatorioEventos.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/relatorioEventosLPR',
        name: 'RelatorioEventosLPR',
        props: true,
        component: () =>
            import(/* webpackChunkName: "RelatorioEventosLPR" */ "../views/RelatorioEventosLPR.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/relatorioEventosM',
        name: 'RelatorioEventosM',
        props: true,
        component: () =>
            import(/* webpackChunkName: "RelatorioEventosM" */ "../views/RelatorioEventosMultiplo.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/log',
        name: 'log',
        props: true,
        component: () =>
            import(/* webpackChunkName: "log" */ "../views/Log.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/manual',
        name: 'Manual',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Manual" */ "../views/Manual.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/tensorFlow',
        name: 'TensorFlow',
        props: true,
        component: () =>
            import(/* webpackChunkName: "TensorFlow" */ "../views/TensorFlow.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/devUtils',
        name: 'DevUtils',
        props: true,
        component: () =>
            import(/* webpackChunkName: "DevUtils" */ "../views/DevUtils.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/cameraModel',
        name: 'CameraModel',
        props: true,
        component: () =>
            import(/* webpackChunkName: "CameraModel" */ "../views/CameraModel/CameraModel.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/automacao',
        name: 'Automacao',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Automacao" */ "../views/Automacao/Automacao.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/dispositivos',
        name: 'Dispositivos',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Automacao" */ "../views/Dispositivos/Dispositivos.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/clienteAutomacao',
        name: 'ClienteAutomacao',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Automacao" */ "../views/ClienteAutomacao.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/manualView',
        name: 'ManualView',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Manual" */ "../views/ManualViewer.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/estatisticas',
        name: 'Estatisticas',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Estatisticas" */ "../views/Estatisticas/Estatisticas.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/visualizacao',
        name: 'Visualizacao',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Visualizacao" */ "../views/Visualizacao/Visualizacao.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/device/wifiDevice',
        name: 'WifiDevice',
        props: true,
        component: () =>
            import(/* webpackChunkName: "WifiDevice" */ "../views/Dispositivos/Screen/WifiDevice.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/proxy',
        name: 'Proxy',
        props: true,
        component: () =>
            import(/* webpackChunkName: "Proxy" */ "../views/Proxy/Proxy.vue"),
        meta: { requiresAuth: true }
    },
]
// router.replace({ path: '*', redirect: '/' })
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    store.dispatch('setTargetUrl', window.location.pathname);
    let logged = store.getters.getLogged
    if (to.matched.some(record => record.meta.requiresAuth)) {
        document.title = `Heimdall - ${to.name}`.toUpperCase();
        // user && user.admin ? next() : next({ path: '/' })
        store.dispatch('setActualRoute', to.path);
        logged ? next() : next({ path: '/login' })
    } else {
        store.dispatch('setActualRoute', to.path);
        next()
    }

})

export default router