import axios from 'axios'
export default {
    state: {
        Proxys: [],
        proxyStatus: [],
    },
    mutations: {
        setProxys(state, payload) {
            state.Proxys = payload
        },
        setProxysStatus(state, payload) {
            state.proxyStatus = payload
        },
    },
    actions: {
        setProxys(context, payload) {
            context.commit('setProxys', payload)
        },
        setProxysStatus(context, payload) {
            context.commit('setProxysStatus', payload)
        },
        loadProxys(context) {
            axios(`proxy`).then((resp) => {
                context.commit('setProxys', resp.data)
            });
        },
        loadProxysStatus(context) {
            axios(`proxy/status`).then((resp) => {
                context.commit('setProxysStatus', resp.data)
            });
        },
        saveProxy(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/proxy${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Proxy salvo com Sucesso");
                    context.dispatch('loadProxys')
                }
            });
        },
        deletProxy(context, proxy) {
            console.log(proxy, `/proxy/${proxy.id}`);
            axios.delete(`/proxy/${proxy.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Proxy Deletado com Sucesso");
                context.dispatch('loadProxys');
                }
            });
        },
    },
    getters: {
        getProxys(state) {
            return state.Proxys
        },
        getProxysStatus(state) {
            return state.proxyStatus
        },
        getProxyTypeById: (state) => (value) => {
            if (!state.moniTypes) {
                return value
            }
            let gp = state.moniTypes.find(g =>
                g.value == value
            )
            return gp ? `${gp.value} - ${gp.text}` : ''
        },
        getProxyNameById: (state) => (id) => {
            if (!state.Proxys) {
                return id
            }
            let gp = state.Proxys.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getProxyById: (state) => (id) => {
            if (!state.Proxys) {
                return id
            }
            let gp = state.Proxys.find(g =>
                g.id == id
            )
            return gp || {}
        },
        getProxysSelect: (state) =>  {
            let gp = []
            state.Proxys.forEach(element => {
                gp.push({value: element.id, text: `${element.id} - ${element.name}`})
            });
            return gp 
        },
        getProxyStatusById: (state) => (id) => {
            if (!state.proxyStatus) {
                return [];
            }
            let gp = state.proxyStatus.find(g =>
                g.id == id
            )
            return gp || [];
        },
    },
}