import axios from 'axios'
export default {
    state: {
        clientes: [],
    },
    mutations: {
        setClientes(state, payload) {
            state.clientes = payload
        },
    },
    actions: {
        setClientes(context, payload) {
            context.commit('setClientes', payload)
        },
        loadClientes(context) {
            axios(`cliente`).then((resp) => {
                // console.log(resp.data);
                context.commit('setClientes', resp.data)
            });
        },
        saveCliente(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/cliente${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Cliente salvo com Sucesso");
                    context.dispatch('loadClientes')
                }
            });
        },
        deletCliente(context, cliente) {
            axios.delete(`/cliente/${cliente.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Cliente Deletado com Sucesso");
                context.dispatch('loadClientes');
                }
            });
        },
    },
    getters: {
        getClientes(state) {
            return state.clientes
        },
        getClienteNameById: (state) => (id) => {
            if (!state.clientes) {
                return id
            }
            let gp = state.clientes.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getClienteById: (state) => (id) => {
            if (!state.clientes) {
                return id
            }
            let gp = state.clientes.find(g =>
                g.id == id
            )
            return gp ? gp : {}
        },
        getClientesSelect: (state) =>  {
            let gp = []
            state.clientes.forEach(element => {
                gp.push({value: element.id, text: `${element.id} - ${element.name}`})
            });
            return gp 
        },
    },
}