<template>
  <div class="home ma-2">
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Usuários Cadastrados = {{ sts.users[0].length }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Niveis Cadastrados = {{ sts.userLevels[0].length }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Permissões Cadastradas = {{ sts.userPermissions[0].length }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Recursos Cadastrados = {{ sts.userResources[0].length }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Rotas Cadastradas = {{ sts.routes }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      sts: [],
    };
  },
  methods: {
    getStats() {
      this.$http(`status`).then((resp) => {
        if (resp.data) {
          this.sts = resp.data;
        }
      });
    },
  },
  created() {
    this.getStats();
  },
};
</script>
