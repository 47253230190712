<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title>
          Cadastro de Permissões
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getAll"> Atualizar Dados </v-btn>
        </v-card-title>

        <v-form>
          <v-container>
            <v-row>
              <v-checkbox
                label="Ativo"
                v-model="permissao.enabled"
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-col cols="9">
                <v-autocomplete
                  v-model="permissao.levelId"
                  :items="niveis"
                  label="Nivel de Acesso"
                  outlined
                  required
                ></v-autocomplete>
                <!-- <v-select
                  v-model="permissao.levelId"
                  :items="niveis"
                  label="Nivel de Acesso"
                  outlined
                  required
                ></v-select> -->
              </v-col>
              <v-col>
                <v-btn class="mt-2" color="warning" @click="insertAll">
                  Adicionar Todos
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="permissao.resourceId"
                  :items="recursos"
                  label="Recurso"
                  outlined
                  required
                ></v-autocomplete>
                <!-- <v-select
                  v-model="permissao.resourceId"
                  :items="recursos"
                  label="Recurso"
                  outlined
                  required
                ></v-select> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-checkbox label="GET" v-model="permissao.get"></v-checkbox>
                <v-checkbox
                  label="GET PARAMETROS"
                  v-model="permissao.getParam"
                ></v-checkbox>
                <v-checkbox
                  label="GET QUERY STRING"
                  v-model="permissao.getQuery"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox label="POST" v-model="permissao.post"></v-checkbox>
                <v-checkbox
                  label="POST PARAMETROS"
                  v-model="permissao.postParam"
                ></v-checkbox>
                <v-checkbox
                  label="POST QUERY STRING"
                  v-model="permissao.postQuery"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox label="PUT" v-model="permissao.put"></v-checkbox>
                <v-checkbox
                  label="PUT PARAMETROS"
                  v-model="permissao.putParam"
                ></v-checkbox>
                <v-checkbox
                  label="PUT QUERY STRING"
                  v-model="permissao.putQuery"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  label="DELETE"
                  v-model="permissao.delete"
                ></v-checkbox>
                <v-checkbox
                  label="DELETE PARAMETROS"
                  v-model="permissao.deleteParam"
                ></v-checkbox>
                <v-checkbox
                  label="DELETE QUERY STRING"
                  v-model="permissao.deleteQuery"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="permissao.description"
                  rows="2"
                  label="Descrição"
                  hint="Digite uma descrição da Permissão"
                  :counter="255"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="clear">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save(permissao)">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <!-- DIALOG FILTRO  -->
      <v-card class="my-1">
        <v-card-title>
          Filtro
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showFilter = !showFilter">
            {{ showFilter ? "Esconder" : "Mostrar" }} Filtro
          </v-btn></v-card-title
        >
        <v-card-text v-show="showFilter">
          {{ filtro }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="filtro.level"
                label="Nivel de Acesso"
                hint="Filtra pelo Nivel de Acesso"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filtro.to"
                :items="routes"
                label="Rota"
                hint="Filtrar pela Rota"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showFilter">
          <v-spacer></v-spacer>
          <v-btn depressed color="warning" @click="filtro = {}"> Limpar </v-btn>
          <v-btn depressed color="primary" @click="get(true)"> Filtrar </v-btn>
        </v-card-actions>
      </v-card>
      <!-- DIALOG COPIAR NIVEL -->
      <v-card class="my-1">
        <v-card-title>
          Copiar Nivel
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showCopy = !showCopy">
            {{ showCopy ? "Esconder" : "Mostrar" }} Copiar
          </v-btn>
        </v-card-title>
        <v-card-text v-show="showCopy">
          {{ copy }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="copy.copy"
                label="Selecione o Nivel"
                hint="Nivel que será o Modelo"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="copy.paste"
                :items="niveis"
                label="Selecione o Nivel"
                hint="Nivel que recebera os dados"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showCopy">
          <v-spacer></v-spacer>
          <v-checkbox
            class="mx-4"
            label="Limpar antes de Copiar"
            v-model="copy.clearBefore"
          ></v-checkbox>
          <v-btn depressed color="warning" @click="copyLevel"> Copiar </v-btn>
        </v-card-actions>
      </v-card>

      <v-row class="mt-2">
        <v-col>
          <Table
            :headers="header"
            :items="permissoes"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "./TablePerm";
export default {
  name: "Recursos",
  components: {
    Table,
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      permissao: {
        id: null,
        enabled: true,
        nvRecursoId: null,
        nvAcessoId: null,
        get: true,
        getParam: true,
        put: true,
        putParam: true,
        post: true,
        postParam: true,
        delete: true,
        deleteParam: true,
        description: "",
      },
      permissoes: [],
      recursos: [],
      niveis: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nivel", value: "levelsName" },
        { text: "Recurso", value: "resourceName" },
        { text: "Path", value: "resourcePath" },
        { text: "Descrição", value: "description" },
        { text: "GET", value: "get" },
        { text: "GET Params", value: "getParam" },
        { text: "GET Query", value: "getQuery" },
        { text: "POST", value: "post" },
        { text: "POST Params", value: "postParam" },
        { text: "POST Query", value: "postQuery" },
        { text: "PUT", value: "put" },
        { text: "PUT Params", value: "putParam" },
        { text: "PUT Query", value: "putQuery" },
        { text: "DELETE", value: "delete" },
        { text: "DELETE Param", value: "deleteParam" },
        { text: "DELETE Query", value: "deleteQuery" },
        { text: "Ativo", value: "ativo" },
        { text: "Ações", value: "actions" },
      ],
      filtro: { to: "", level: "" },
      copy: {},
      showCopy: false,
      showFilter: false,
      routes: [],
    };
  },
  methods: {
    get(filtro) {
      let url = "userpermission";
      if ((this.filtro.level || this.filtro.to) && filtro) {
        url += this.filtro.level ? "&nivel=" + this.filtro.level : "";
        url += this.filtro.to ? "&to=" + this.filtro.to : "";
        url = url.replace("&", "?");
      } else {
        url = "userpermission";
      }
      this.$http(url).then((resp) => {
        resp.data.forEach((element) => {
          element.ativo = element.enabled ? "S" : "N";
          this.routes.push(element.resourcePath);
        });
        this.permissoes = resp.data;
      });
    },
    getRecursos() {
      this.$http("userresource").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${
            element.description
          }) > {${element.path}} ${element.public ? "PUBLICA" : ""}`;
          element.value = element.id;
        });
        this.recursos = resp.data;
      });
    },
    getNiveis() {
      this.$http("userlevel").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${
            element.description
          }) ${element.superUser ? "SUPERUSUÁRIO" : ""}`;
          element.value = element.id;
        });
        this.niveis = resp.data;
      });
    },
    save(permissao) {
      const metodo = permissao.id ? "put" : "post";
      const finalUrl = permissao.id ? `/${permissao.id}` : "";
      this.$http[metodo](`/userpermission${finalUrl}`, permissao).then(() => {
        this.get();
        this.clear();
        (this.snackbar = true), (this.text = "Salvo com Sucesso");
      });
    },
    copyLevel() {
      this.$http.post(`/userpermission/copy`, this.copy).then(() => {
        this.get();
        this.clear();
        (this.snackbar = true), (this.text = "Copiado com Sucesso");
      });
    },
    insertAll() {
      if (!this.permissao.levelId) {
        return;
      }
      this.$http
        .post(`/userpermission/insertAll/${this.permissao.levelId}`)
        .then(() => {
          this.get();
          this.clear();
          (this.snackbar = true), (this.text = "Todos os Recursos inseridos");
        });
    },
    edit(rec) {
      this.permissao = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(id) {
      this.$http.delete(`/userpermission/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    getAll() {
      this.get();
      this.getRecursos();
      this.getNiveis();
    },
    clear() {
      this.permissao = {
        id: null,
        enabled: true,
        nvRecursoId: null,
        nvAcessoId: null,
        get: true,
        getParam: true,
        put: true,
        putParam: true,
        post: true,
        postParam: true,
        delete: true,
        deleteParam: true,
        description: "",
      };
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>

<style>
</style>