<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :show-select="enableSelec"
      :single-select="enableSingleSelec"
      :options="{ itemsPerPage: rows }"
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:item.picture="{ item }" v-if="enablePicture">
        <img :src="item.picture" width="100" />
      </template>
      <template v-slot:item.picture2="{ item }" v-if="enablePicture">
    <img :src="item.picture2" width="100" />
      </template>
      <template v-slot:item.actions="{ item }" v-show="enableActions">
        <v-icon small class="mr-2" @click="editar(item)">mdi-pencil</v-icon>
        <v-icon small @click="deletar(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "TableDialog",
  data:()=>{
    return{
      search:'',
      selected: [],
    }
  },
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    enableSelec: {
      type: Boolean,
      default: false,
    },
    enableSingleSelec: {
      type: Boolean,
      default: true,
    },
    enableActions: {
      type: Boolean,
      default: false,
    },
    enablePicture: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 15,
    },
  },
  methods: {
    editar(item) {
      this.$emit("edit", item);
    },
    deletar(item) {
      let del = confirm("Tem Certeza?\nEsta Operção é Irreversivel");
      if (del) {
        this.$emit("delete", item);
      }
    },
  },
  watch:{
    selected(){
      this.$emit("selected", this.selected[0]);
      this.$emit("multiSelected", this.selected);
    },
  }
};
</script>

<style>

</style>