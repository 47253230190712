<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title> Cadastro de Niveis <v-spacer></v-spacer>
          <v-btn color="primary" @click="get"> Atualizar Dados </v-btn></v-card-title>
        
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="1">
                <v-checkbox v-model="nivel.enabled" label="Ativo"></v-checkbox>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model.number="nivel.orderLvl"
                  :counter="50"
                  label="Ordem do Nivel"
                  hint="Ordem do Nivel"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="nivel.name"
                  :counter="50"
                  label="Nome do Nivel"
                  hint="Digite algum nome para este nivel"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="nivel.description"
                  :counter="50"
                  label="Descrição"
                  hint="Digite alguma Descrição para este nivel"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-checkbox v-model="nivel.superUser" label="Super Usuário"></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center">
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="clear">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save(nivel)">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-row>
        <v-col>
          <Table
            :headers="header"
            :items="niveis"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-col>
      </v-row>
    </div>
     <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "../TableGeneric";
export default {
  name: "Recursos",
  components: {
    Table,
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      nivel: {
        id: null,
        enabled: true,
        onlyApp: false,
      },
      niveis: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Ordem", value: "orderLvl" },
        { text: "Nome", value: "name" },
        { text: "Descrição", value: "description" },
        { text: "Ativo", value: "ativo" },
        { text: "Super Usuário", value: "superUsuario" },
        { text: "Cadastrado", value: "criado" },
        { text: "Modificado", value: "atualizado" },

        { text: "Ações", value: "actions" },
      ],
      routes:[],
    };
  },
  methods: {
    get() {
      this.$http("userlevel").then((resp) => {
        this.niveis = resp.data;
        resp.data.forEach(element => {
          element.ativo = element.enabled ? "SIM" : "NÃO"
          element.superUsuario = element.superUser ? "SIM" : "NÃO"
          element.criado = new Date(element.createdAt).toLocaleString();
          element.atualizado = new Date(element.updatedAt).toLocaleString();

        });
      });
    },
    save(nivel) {
      const metodo = nivel.id ? "put" : "post";
      const finalUrl = nivel.id ? `/${nivel.id}` : "";
      // console.log(nivel);
      this.$http[metodo](`userlevel${finalUrl}`, nivel).then((resp) => {
        if(resp){

          this.get();
          this.clear();
          this.snackbar = true,
          this.text = "Salvo com Sucesso"
        }
      });
    },
    edit(rec) {
      this.nivel = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(id) {
      this.$http.delete(`/userlevel/${id}`).then(() => {
        this.get();
        this.snackbar = true,
        this.text = "Deletado com Sucesso"
      });
    },
    clear() {
      this.nivel = {
        id: null,
        enabled: true,
      };
    },
  },
  mounted() {
    this.get();
    let routes = this.$router.options.routes;
    this.routes = routes.map((ele) => {
      return { text: `${ele.name} - (${ele.path})`, value: ele.path };
    });
  },
};
</script>

<style>
</style>