export default {
    state: {
        text: '',
        timeout: 2000,
        snackbar: false,
        color: '',
        closable: false,
    },
    mutations: {
        setText(state, payload) {
            state.text = payload
        },
        setColor(state, payload) {
            state.color = payload
        },
        setTime(state, payload) {
            state.timeout = payload
        },
        setSnackbar(state, payload) {
            state.snackbar = payload
        },
        setClosable(state, payload) {
            state.closable = payload
        },
    },
    actions: {
        showSnack(context, payload) {
            context.commit('setText', payload)
            context.commit('setColor', null)
            context.commit('setSnackbar', true)
        },
        closableSnack(context, payload) {
            if (typeof payload === 'string') {
                context.commit('setText', payload)
                context.commit('setColor', null)
                context.commit('setSnackbar', true)
                context.commit('setClosable', true)
            } else if (typeof payload === 'object') {
                context.commit('setText', payload.text)
                context.commit('setColor', payload.color || null)
                context.commit('setSnackbar', true)
                context.commit('setClosable', true)
            }
        },
        showErrorSnack(context, payload) {
            context.commit('setText', payload)
            context.commit('setColor', "red")
            context.commit('setSnackbar', true)
        },
        showSuccessSnack(context, payload) {
            context.commit('setText', payload)
            context.commit('setColor', 'green')
            context.commit('setSnackbar', true)
        },
        resetSnack(context) {
            context.commit('setText', null)
            context.commit('setColor', null)
            context.commit('setSnackbar', false)
            context.commit('setClosable', false)
        },
        showSnackColor(context, payload) {
            context.commit('setText', payload.text)
            context.commit('setColor', payload.color)
            context.commit('setSnackbar', true)
        },
    },
    getters: {
        snackbar(state) {
            return {
                text: state.text,
                timeout: state.timeout,
                snackbar: state.snackbar,
                color: state.color,
            }
        },
    }
}