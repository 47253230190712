<template>
  <div>
    <v-dialog v-model="value" persistent>
      <v-card>
        <v-card-title>
          Selecione os atributos para rota - {{ path }}
        </v-card-title>
        <v-card-text>
          <div v-for="cf in getConfig()" :key="cf">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <v-label>Descrição da Propriedade:</v-label>
               <h2> {{ getName(cf) || cf }} </h2>
              </v-col>
              <v-col>
                <div v-for="cf1 in getConfig(cf)" :key="cf1.key">
                  <v-checkbox
                    v-show="typeof cf1.value === 'boolean'"
                    :label="cf1.key.toUpperCase()"
                    v-model="config[cf][cf1.key]"
                    :disabled="onlyShow"
                  ></v-checkbox>
                  <v-text-field
                    v-model="config[cf][cf1.key]"
                    :label="cf1.key.toUpperCase()"
                    v-show="typeof cf1.value === 'string' && cf1.key != 'name'"
                    :disabled="onlyShow"
                  ></v-text-field>
                  <v-text-field
                    v-model.number="config[cf][cf1.key]"
                    :label="cf1.key.toUpperCase()"
                    v-show="typeof cf1.value === 'number'"
                    type="number"
                    :disabled="onlyShow"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Usar esta Configuração
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogColor",
  props: ["value", "config", "path", "onlyShow"],
  data: () => ({}),
  methods: {
    close() {
      this.$emit("input", false);
    },
    save() {
      this.$emit("config", this.config);
      this.$emit("input", false);
    },
    clear() {},
    getConfig(subValue) {
      let ret = [];
      // se for solicitado um valor do objeto
      if (subValue) {
        if (Object.hasOwnProperty.call(this.config, subValue)) {
          for (const key in this.config[subValue]) {
            if (Object.hasOwnProperty.call(this.config[subValue], key)) {
              ret.push({ key: key, value: this.config[subValue][key] });
            }
          }
          return ret;
        }
      }
      // se for solicitado o objeto sem parametros seto um array de nomes
      for (const key in this.config) {
        if (Object.hasOwnProperty.call(this.config, key)) {
          ret.push(key);
        }
      }
      return ret;
    },
    getName(subValue) {
      if (subValue) {
        if (this.config[subValue].name) {
          return this.config[subValue].name;
        } else {
          return subValue;
        }
      }
      return "aham";
    },
  },
};
</script>

<style scoped>
</style>