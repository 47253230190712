<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title>
          Cadastro de Menus
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="get(false)"> Atualizar </v-btn>
        </v-card-title>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-row>
                  <v-checkbox v-model="menu.enabled" label="Ativo"></v-checkbox>
                  <v-text-field
                    class="mx-2"
                    v-model="menu.orderMenu"
                    label="Ordem no Menu"
                    hint="Ordem que sera exibido"
                    required
                    type="number"
                    outlined
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col>
                <span>
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-icon
                          class="mr-5"
                          :color="menu.color"
                          :x-large="menu.size == 'x-large'"
                          :large="menu.size == 'large'"
                          :medium="menu.size == 'medium'"
                          :dense="menu.size == 'dense'"
                          :small="menu.size == 'small'"
                          :x-small="menu.size == 'x-small'"
                        >
                          {{ menu.icon }}</v-icon
                        >
                        {{ menu.name }}
                      </v-row>
                    </v-card-text>
                  </v-card>
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="menu.name"
                  :counter="50"
                  label="Texto do Menu"
                  hint="Digite como sera exibido o nome desde Menu"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="menu.icon"
                  label="Icone"
                  hint="Selecione um Icone"
                  :items="icons"
                  persistent-hint
                  outlined
                  prepend-inner-icon="mdi-format-color-fill"
                  @click:prepend-inner="openSelectColor"
                >
                  <template v-slot:append-item>
                    <v-slide-x-reverse-transition mode="out-in">
                    </v-slide-x-reverse-transition>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon class="mx-2"> {{ item }}</v-icon>
                    {{ item }}
                  </template>
                </v-autocomplete>
                <!-- <v-card outlined>
                  <v-row>
                    <v-col cols="6">
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        :items="colors"
                        label="Cor"
                        hint="Selecione uma Cor"
                        v-model="menu.color"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        :items="sizes"
                        label="Tamanho"
                        hint="Selecione o Tamanho"
                        v-model="menu.size"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card> -->
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="routes"
                  v-model="menu.to"
                  outlined
                  @change="showMeta"
                  label="Selecione a Rota"
                  hint="Selecione a rota que o menu envia"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="menu.description"
                  rows="1"
                  label="Descrição"
                  hint="Digite uma descrição se necessario"
                  :counter="255"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="menu.level"
                  :items="niveis"
                  label="Nivel de Acesso"
                  outlined
                  required
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <v-textarea
                  v-model="json"
                  rows="1"
                  hint="Json que sera enviado para o from nessa rota deste nivel"
                  persistent-hint
                  counter
                  outlined
                ></v-textarea>
              </v-col>
            </v-row> -->
            <v-row>
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="clear">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save"> Salvar </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>

      <v-card class="my-1">
        <v-card-title>
          Filtro
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showFilter = !showFilter">
            {{ showFilter ? "Esconder" : "Mostrar" }} Filtro
          </v-btn></v-card-title
        >
        <v-card-text v-show="showFilter">
          {{ filtro }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="filtro.level"
                label="Nivel de Acesso"
                hint="Filtra pelo Nivel de Acesso"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filtro.to"
                :items="routes"
                label="Rota"
                hint="Filtrar pela Rota"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showFilter">
          <v-spacer></v-spacer>
          <v-btn depressed color="warning" @click="filtro = {}"> Limpar </v-btn>
          <v-btn depressed color="primary" @click="get(true)"> Filtrar </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="my-1">
        <v-card-title>
          Copiar Nivel
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showCopy = !showCopy">
            {{ showCopy ? "Esconder" : "Mostrar" }} Copiar
          </v-btn>
        </v-card-title>
        <v-card-text v-show="showCopy">
          {{ copy }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="copy.copy"
                label="Selecione o Nivel"
                hint="Nivel que será o Modelo"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="copy.paste"
                :items="niveis"
                label="Selecione o Nivel"
                hint="Nivel que recebera os dados"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showCopy">
          <v-spacer></v-spacer>
          <v-checkbox
            class="mx-4"
            label="Limpar antes de Copiar"
            v-model="copy.clearBefore"
          ></v-checkbox>
          <v-btn depressed color="warning" @click="copyData()"> Copiar </v-btn>
        </v-card-actions>
      </v-card>

      <v-row>
        <v-col>
          <Table
            :headers="header"
            :items="menus"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
    <DialogColor v-model="dialogColor" @color="selectColor" :color="menu.color"/>
  </div>
</template>

<script>
import Table from "./TablePerm.vue";
import DialogColor from "../Dialogs/DialogColor.vue";
export default {
  name: "Menus",
  components: {
    Table,
    DialogColor
  },
  data: () => {
    return {
      routes: [],
      text: "",
      snackbar: false,
      sizes: ["x-small", "small", "dense", "medium", "large", "x-large"],
      colors: ["", "red", "orange", "yellow", "green", "blue", "purple"],
      icons: [],
      menu: {
        id: null,
        enabled: true,
        icon: "",
        size: "",
        color: "",
        orderMenu: 1,
      },
      niveis: [],
      menus: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Ordem", value: "orderMenu" },
        { text: "Texto", value: "name" },
        { text: "Icone", value: "icon" },
        { text: "Cor", value: "color" },
        { text: "Tamanho", value: "size" },
        { text: "Rota", value: "to" },
        { text: "Descrição", value: "description" },
        { text: "Nivel", value: "nivel" },
        // { text: "Nivel", value: "level" },
        { text: "Ações", value: "actions" },
      ],
      validJson: false,
      json: "",
      meta: "",
      filtro: { level: "", to: "" },
      showFilter: false,
      copy: { clearBefore: true },
      showCopy: false,
      dialogColor:false
    };
  },
  methods: {
    getAcesso() {
      this.$http("userlevel").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.id;
        });
        this.niveis = resp.data;
        this.get();
      });
    },
    getIcones() {
      this.$http("icone").then((resp) => {
        let ic = [];
        resp.data.forEach((element) => {
          ic.push(element.name);
        });
        this.icons = ic;
      });
    },
    get(filtro) {
      let url = "userMenu";
      if ((this.filtro.level || this.filtro.to) && filtro) {
        url += this.filtro.level ? "&nivel=" + this.filtro.level : "";
        url += this.filtro.to ? "&to=" + this.filtro.to : "";
        url = url.replace("&", "?");
      } else {
        url = "userMenu";
      }
      this.$http(url).then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.id;
          element.nivel = this.showNivelName(element.level);
        });
        this.menus = resp.data;
      });
    },
    save() {
      const metodo = this.menu.id ? "put" : "post";
      const finalUrl = this.menu.id ? `/${this.menu.id}` : "";
      this.$http[metodo](`/userMenu${finalUrl}`, this.menu).then((resp) => {
        if (resp.status < 400) {
          this.get();
          this.clear();
          (this.snackbar = true), (this.text = "Salvo com Sucesso");
          this.$store.dispatch("loadMenu", { cb: false });
        }
      });
    },
    copyData() {
      this.$http.post(`/userMenu/copy`, this.copy).then(() => {
        (this.snackbar = true), (this.text = "Copiado com Sucesso");
      });
    },
    edit(rec) {
      this.menu = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
      this.meta = "";
      this.showMeta(rec.to);
      this.json = JSON.stringify(rec.parameters);
    },
    delet(id) {
      this.$http.delete(`/userMenu/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    clear() {
      this.menu = {
        id: null,
        enabled: true,
        orderMenu: 1,
      };
      this.json = "";
    },
    icon(icon) {
      this.menu.icon = icon.icon;
      this.menu.size = icon.size;
      this.menu.color = icon.color;
    },
    newConfig(e) {
      this.menu.parameters = e;
      this.json = JSON.stringify(e);
      this.save();
    },
    showMeta(item) {
      let idx = this.routes.findIndex((e) => {
        return e.value == item;
      });
      this.meta = this.routes[idx].meta;
      this.json = "";
      // console.log(this.meta);
    },
    showNivelName(id) {
      let nv = this.niveis.find((e) => e.id == id);
      if (nv) {
        return `${nv.id} - ${nv.name}`;
      } else {
        return id;
      }
    },
    openSelectColor(){
      // console.log("openSelectColor");
      this.dialogColor = true;
    },
    selectColor(color){
      // console.log(color);
      this.menu.color = color;
    }
  },
  mounted() {
    // this.get();
    this.getAcesso();
    this.getIcones();
    //pegando as rotas disponiveis na aplicação
    let routes = this.$router.options.routes;
    routes.forEach((ele) => {
      this.routes.push({
        text: `${ele.name} - (${ele.path})`,
        value: ele.path,
        meta: ele.meta,
      });
    });
  },
};
</script>

<style>
</style>