const config = {
  permNav: {
    name: "Permissão de Back",
    text: "Permissão de Back",
    enabled: true
  },
  permFNav: {
    name: "Permissões do Front",
    text: "Permissões do Front",
    enabled: true
  },
  usrMenuFNav: {
    name: "Menus do Front",
    text: "Menus do Front",
    enabled: true
  },
  recNav: {
    name: "Recursos",
    text: "Recursos",
    enabled: true
  },
  lvlNav: {
    enabled: true, name: "Níveis de Acesso",
    text: "Níveis de Acesso",
  },
  usrNav: {
    enabled: true, name: "Usuários",
    text: "Usuários",
  },
}
module.exports = config;