const store = require('../store/index');
const router = require('../router/index');

const getText = (elName) => {
  let data = getConfig(elName);
  if (Object.hasOwnProperty.call(data.config, elName)) {
    if (data.config[elName].text) {
      return data.config[elName].text;
    }
  }
  if (data.defaultConfig) {
    if (Object.hasOwnProperty.call(data.defaultConfig, elName)) {
      if (data.defaultConfig[elName].text) {
        return data.defaultConfig[elName].text;
      }
    }
  }
  return "no text";
}

const getEnabled = (elName) => {
  let data = getConfig(elName);
  if (Object.hasOwnProperty.call(data.config, elName)) {
    if (data.config[elName].enabled != undefined) {
      return data.config[elName].enabled;
    }
  }
  if (data.defaultConfig) {
    if (Object.hasOwnProperty.call(data.defaultConfig, elName)) {
      if (data.defaultConfig[elName].enabled != undefined) {
        return data.defaultConfig[elName].enabled;
      }
    }
  }
  return false;
}
// elName = nome do elemento ou objeto
// param = nome do parametro dentro do objeto
// defValue = valor padrão, caso não encontre o parametro
const getCustom = (elName, param, defValue) => {
  let data = getConfig(elName);
  if (Object.hasOwnProperty.call(data.config, elName)) {
    if (data.config[elName][param] != undefined) {
      return data.config[elName][param];
    }
  }
  if (data.defaultConfig) {
    if (Object.hasOwnProperty.call(data.defaultConfig, elName)) {
      if (data.defaultConfig[elName][param] != undefined) {
        return data.defaultConfig[elName][param];
      }
    }
  }
  return defValue;
}

const getConfig = (elName) => {
  let atual = store.default.getters.getActualRoute;
  let defaultConfig = router.default.options.routes.find((e) => e.path == atual);
  let config = store.default.getters.getRouteByPath(atual);
  return { elName, defaultConfig: defaultConfig.available, config, atual };
}

module.exports = {
  getText,
  getEnabled,
  getCustom
}