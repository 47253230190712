<template>
  <div class="home ma-2">
    <v-card>
      <v-card-title> Criador de Rota </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="newRoute.tableName"
              :counter="50"
              label="Nome da Tabela Banco"
              hint="Digite o Nome da tabela do Banco para este recurso"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="newRoute.routeName"
              :counter="50"
              label="Nome do Recurso"
              hint="Digite o Nome do Recurso"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="newRoute.path"
              :counter="50"
              label="Path da Rota"
              hint="Digite o Path da Rota"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col>
            <v-checkbox
              label="Gerar Tabela?"
              v-model="newRoute.createTable"
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              label="Gerar Arquivos?"
              v-model="newRoute.createFiles"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Adicionar Coluna
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Novo Atributo</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      v-model="newAttribute.name"
                      label="Nome da Coluna / Atributo"
                      required
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-select
                      v-model="newAttribute.type"
                      :items="['VARCHAR', 'INT', 'TIMESTAMP', 'BOOLEAN']"
                      label="Tipo da Coluna*"
                      required
                    ></v-select>
                  </v-row>
                  <v-row v-if="newAttribute.type === 'VARCHAR'">
                    <v-text-field
                      v-model="newAttribute.length"
                      label="Tamanho da Coluna"
                      type="number"
                      required
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-checkbox
                      v-model="newAttribute.null"
                      label="Permite Nulo"
                    ></v-checkbox>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="addAttribute">
                  Adicionar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nome da Coluna</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Tamanho</th>
                    <th class="text-left">Nulo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in newRoute.attributes" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.length }}</td>
                    <td>{{ item.null ? "SIM" : "NÃO" }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="addRoute"> Criar Rota </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      sts: [],
      dialog: false,
      newRoute: {
        createController: true,
        createRoute:true,
        createTable:true,
        attributes: [],
      },
      newAttribute: {},
    };
  },
  methods: {
    addAttribute() {
      this.newRoute.attributes.push(this.newAttribute);
      this.dialog = false;
      this.newAttribute = {};
    },
    cancel() {
      this.dialog = false;
      this.newAttribute = {};
    },
    addRoute() {
      this.$http.post(`routes`, this.newRoute).then((resp) => {
        if (resp.data) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Rota adicionada");
        }
      });
    },
  },
};
</script>
