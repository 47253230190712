<template>
  <v-card color="blue-grey-lighten-5" id="top">
    <v-card-title>
      <p>Gerador de requisições</p>
    </v-card-title>
    <v-card-text>
      <!-- url -->
      <v-row>
        <v-col>
          <v-card color="blue-grey-lighten-4">
            <v-card-title>Url</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <v-select :items="['get', 'post', 'put', 'delete']" v-model="request.method" label="Selecione o Metodo"
                    outlined presistent-hint>
                  </v-select>
                </v-col>
                <v-col>
                  <v-combobox v-if="routes.length" v-model="request.url" label="URL" hint="URL do recurso" :items="routes"
                    outlined presistent-hint clearable>
                  </v-combobox>
                  <v-text-field v-else v-model="request.url" label="URL" hint="URL do recurso" outlined
                    presistent-hint></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-btn color="success" size="x-large" @click="sendRequest">Enviar</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="urlShow" label="URL usada" outlined readonly></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- linha do corpo -->
      <v-row>
        <v-col>
          <v-card color="blue-grey-lighten-4">
            <v-card-title>Corpo (Body)</v-card-title>
            <v-card-text class="blue-grey-lighten-4">
              <v-row>
                <v-col>
                  <v-select :items="['JSON', 'MULTIPART']" label="Formato do corpo" outlined v-model="request.bodyType"
                    @update:model-value="changeContentType">
                  </v-select>
                </v-col>
                <v-col v-if="request.bodyType == 'MULTIPART'" cols="auto">
                  <v-btn @click="addMultipart" color="success" size="x-large">Adicionar Atributo</v-btn>
                </v-col>
              </v-row>
              <!-- json -->
              <v-row v-if="request.bodyType == 'JSON'">
                <v-col>
                  <v-textarea v-model="request.dataText" rows="3" label="DATA" hint="Json que será enviado"
                    :color="validParameters ? 'green' : 'red'" @input="jsonValidate" outlined auto-grow
                    presistent-hint></v-textarea>
                </v-col>
                <v-col cols="auto">
                  <v-btn @click="formatJson" color="success" size="x-large">Formatar</v-btn>
                </v-col>
              </v-row>
              <!-- multipart -->
              <v-row v-else>
                <v-col>
                  <v-row v-for="(f, idx) in request.formDataBody" :key="idx">
                    <v-col>
                      <v-text-field v-model="request.formDataBody[idx].key" label="Nome do Atributo"
                        presistent-hint></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-if="request.formDataBody[idx].type == 'text'"
                        v-model="request.formDataBody[idx].value" label="Valor do Atributo"
                        presistent-hint></v-text-field>
                      <v-file-input v-else v-model="request.formDataBody[idx].value" prepend-icon="" label="Arquivo"
                        presistent-hint counter-size-string="0" counter=""></v-file-input>
                    </v-col>
                    <v-col cols="2">
                      <v-select :items="['text', 'file']" v-model="request.formDataBody[idx].type"
                        label="Tipo do Atributo" outlined presistent-hint>
                      </v-select>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn size="x-large" color="error" @click="request.formDataBody.splice(idx, 1)"> <v-icon
                          class="mr-2">mdi-delete</v-icon> Remover</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- linha da query -->
      <v-row>
        <v-col>
          <v-card color="blue-grey-lighten-4">
            <v-card-title>
              <v-row>
                <v-col> Query </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn @click="request.queryData.push({ value: '', key: '' })" color="success" size="x-large">
                    <v-icon>mdi-plus</v-icon> Adicionar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row v-for="(f, idx) in request.queryData" :key="idx">
                    <v-col>
                      <v-text-field v-model="request.queryData[idx].key" label="Nome do Atributo"
                        presistent-hint></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="request.queryData[idx].value" label="Valor do Atributo"
                        presistent-hint></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn size="x-large" color="error" @click="request.queryData.splice(idx, 1)"> <v-icon
                          class="mr-2">mdi-delete</v-icon> Remover</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- linha dos cabeçalhos -->
      <v-row>
        <v-col>
          <v-card color="blue-grey-lighten-4">
            <v-card-title>
              <v-row>
                <v-col> Cabeçalhos (Headers) </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn @click="request.headersData.push({ value: '', key: '' })" color="success" size="x-large">
                    <v-icon>mdi-plus</v-icon> Adicionar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row v-for="(f, idx) in request.headersData" :key="idx">
                    <v-col>
                      <v-text-field v-model="request.headersData[idx].key" label="Nome do Atributo"
                        presistent-hint></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="request.headersData[idx].value" label="Valor do Atributo"
                        presistent-hint></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn size="x-large" color="error" @click="request.headersData.splice(idx, 1)"> <v-icon
                          class="mr-2">mdi-delete</v-icon> Remover</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- botões -->
      <v-row align-content="center" justify="space-around">
        <v-col cols="auto">
          <v-btn color="warning" size="x-large" @click="clearData">Limpar</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="success" size="x-large" @click="addHistoty">Salvar Requisição</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="success" size="x-large" @click="sendRequest">Enviar</v-btn>
        </v-col>
      </v-row>
      <!-- resposta -->
      <v-row id="responsecard">
        <v-col>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col>
                  {{ responseStatus ? `Responde Code: ${responseStatus}` : "" }}
                </v-col>
                <v-col>
                  {{ reponseTime ? `Tempo de resposta: ${reponseTime} ms` : "" }}
                </v-col>
                <v-col>
                  {{ responseContentType ? `Content-Type: ${responseContentType}` : "" }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row v-show="response.length > 1">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn color="success" @click="goTo('#top')">Voltar ao topo</v-btn>
                </v-col>
              </v-row>
              <v-textarea v-show="response.length > 1" v-model="response" auto-grow label="Response Data" outlined
                presistent-hint></v-textarea>
              <v-row v-show="response.length > 1">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn color="success" @click="goTo('#top')">Voltar ao topo</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- requisições salvas -->
      <v-row>
        <v-col>
          <v-card color="blue-grey-lighten-4">
            <v-card-title>
              <div>
                Requisições Salvas
              </div>
            </v-card-title>
            <v-card-text>
              <v-simple-table class="mt-2">
                <thead>
                  <tr>
                    <th class="text-left">URL</th>
                    <th class="text-left">METODO</th>
                    <th class="text-left">TIPO</th>
                    <th class="text-left">CORPO</th>
                    <th class="text-left">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in reqHistory" :key="index">
                    <td>{{ item.url }}</td>
                    <td>{{ item.method }}</td>
                    <td>{{ item.bodyType }}</td>
                    <td v-if="item.bodyType == 'JSON'">{{ JSON.stringify(item.dataText, null, 2) }}</td>
                    <td v-else>{{ item.formDataBody }}</td>
                    <td>
                      <v-icon @click="removeHistory(index)" class="m-1">
                        mdi-trash-can
                      </v-icon>
                      <v-icon @click="setHistory(index)"> mdi-play </v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RequestCreator",
  data: () => {
    return {
      url: "",
      data: "",
      dataText: "",
      response: "",
      responseStatus: "",
      responseContentType: "",
      reponseTime: 0,
      validParameters: false,
      routes: [],
      temp: {},
      reqHistory: [],
      request: {
        url: "",
        bodyType: "JSON",
        method: "get",
        parameters: "/",
        formDataBody: [{ value: "", key: "", type: "text" }],
        queryData: [{ value: "", key: "" }],
        headersData: [],
        dataText: "",
      },
      urlShow: "",
    };
  },
  methods: {
    // envia a requisição
    sendRequest() {
      // caso não tenha url retorna
      if (!this.request.url) return;
      this.response = "";
      this.responseStatus = "";
      // usado para retirar o /api do inicio da url
      let url = this.request.url.substring(5);
      let time = new Date().getTime();
      this.urlShow = `${this.$http.defaults.baseURL}${url}${this.getQuery()}`;
      this.$http({
        url: `${url}${this.getQuery()}`,
        method: this.request.method,
        data: this.request.bodyType == "JSON" ? this.getJson() : this.getFormData(),
        headers: this.request.headersData.reduce((acc, cur) => {
          acc[cur.key] = cur.value;
          return acc;
        }, {}),
      }).then((resp) => {
        console.log(resp);
        this.reponseTime = new Date().getTime() - time;
        try {
          this.response = JSON.stringify(resp.data, null, 2);
        } catch (error) {
          this.response = resp.data;
        }
        this.responseStatus = resp.status;
        this.responseContentType = resp.headers["content-type"] || "";
        setTimeout(() => {

        }, 500);
      }).catch(err => {
        console.error(err);
        this.reponseTime = new Date().getTime() - time;
        try {
          this.response = JSON.stringify(err.data || "", null, 2);
        } catch (error) {
          this.response = err.data || "";
        }
        this.responseStatus = err.status;
        this.responseContentType = err.headers["content-type"] || "";
      });
      this.goTo("#responsecard");
    },
    // pega os recursos disponiveis
    getRecAvailable() {
      this.$http("/routes").then((resp) => {
        this.routes = resp.data;
      });
    },
    // adiciona um novo campo no multipart
    addMultipart() {
      this.request.formDataBody.push({ value: "", key: "", type: "text" });
    },
    // limpa os dados da requisição
    clearData() {
      this.request = {
        url: "",
        bodyType: "JSON",
        method: "get",
        parameters: "/",
        formDataBody: [{ value: "", key: "", type: "text" }],
        queryData: [{ value: "", key: "" }],
        headersData: [],
        dataText: "",
      };
      this.loadHeaders();
      this.changeContentType();
      this.response = "";
    },
    // verifica se o json é valido
    jsonValidate() {
      try {
        JSON.parse(this.request.dataText);
        this.validParameters = true;
        this.json = this.request.dataText;
      } catch (error) {
        this.validParameters = false;
      }
    },
    // formata o json para ficar legivel
    formatJson() {
      this.request.dataText = JSON.stringify(JSON.parse(this.request.dataText), null, 2);
    },
    // pega o json do campo
    getJson() {
      try {
        return JSON.parse(this.request.dataText);
      } catch (error) {
        return {};
      }
    },
    // adiciona a requisição no historico
    addHistoty() {
      let js = JSON.parse(localStorage.getItem("requests")) || [];
      let data = { ...this.request };
      let idx = data.headersData.findIndex((e) => e.key == "Authorization");
      if (idx > -1) {
        data.headersData.splice(idx, 1);
      }
      js.push({
        ...data
      });
      localStorage.setItem("requests", JSON.stringify(js));
      this.getHistory();
    },
    // pega o historico
    getHistory() {
      let js = localStorage.getItem("requests");
      if (js) {
        this.reqHistory = JSON.parse(js);
      }
    },
    // remove a requisição do historico
    removeHistory(item) {
      let js = localStorage.getItem("requests");
      if (js) {
        this.reqHistory = JSON.parse(js);
        this.reqHistory.splice(item, 1);
        localStorage.setItem("requests", JSON.stringify(this.reqHistory));
        this.getHistory();
      }
    },
    // seta a requisição no form
    setHistory(index) {
      let js = localStorage.getItem("requests");
      if (js) {
        this.reqHistory = JSON.parse(js);
        let a = this.reqHistory[index];
        this.request = { ...a };
        this.goTo("#top");
      }
    },
    // ajusta o content type
    changeContentType() {
      if (this.request.bodyType == "MULTIPART") {
        let idx = this.request.headersData.findIndex((e) => e.key == "Content-Type");
        this.request.headersData.splice(idx, 1);
        this.request.headersData.push({ key: "Content-Type", value: "multipart/form-data" });
      } else {
        let idx = this.request.headersData.findIndex((e) => e.key == "Content-Type");
        this.request.headersData.splice(idx, 1);
        this.request.headersData.push({ key: "Content-Type", value: "application/json" });
      }
    },
    // monta a url e mostra no campo
    showUrl() {
      if (this.request.url && this.request.url.length > 0) {
        this.urlShow = `${this.$http.defaults.baseURL}${this.request.url.substring(5)}${this.getQuery()}`;
      } else {
        this.urlShow = "";
      }
    },
    // monta a query e retorna a string
    getQuery() {
      let query = "";
      this.request.queryData.forEach((e) => {
        if (e.key && e.value) {
          query += `${e.key}=${e.value}&`;
        }
      });
      if (query.length > 0) {
        query = `?${query}`;
        query = query.substring(0, query.length - 1);
      }
      return query
    },
    // monta o formdata e retorna o objeto
    getFormData() {
      let formData = new FormData();
      this.request.formDataBody.forEach((e) => {
        if (e.type == 'file') {
          formData.append(e.key, e.value, e.value.name);
        } else {
          formData.append(e.key, e.value);
        }
      });
      return formData;
    },
    // pega os headers e coloca no array
    loadHeaders() {
      let h = this.$http.defaults.headers.common;
      // peganado os headers
      for (const key in h) {
        if (Object.hasOwnProperty.call(h, key)) {
          this.request.headersData.push({ key: key, value: h[key] });
        }
      }
    },
    goTo(element) {
      const scrollTarget = document.querySelector(element);
      window.scrollTo({
        top: scrollTarget.offsetTop - 64,
        left: 0,
        behavior: "smooth",
      });
    }
  },
  watch: {
    request: {
      handler() {
        this.showUrl();
      },
      deep: true,
    },
  },
  created() {
    this.getRecAvailable();
    this.getHistory();
    // peganado os headers
    this.loadHeaders();
    // ajustar o content type
    this.changeContentType();
  },
};
</script>

<style></style>