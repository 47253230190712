import axios from 'axios'
export default {
  state: {
    automations: [],
  },
  mutations: {
    setAutomations(state, payload) {
      state.automations = payload
    },
  },
  actions: {
    setAutomations(context, payload) {
      context.commit('setAutomations', payload)
    },
    loadAutomations(context) {
      axios(`automation`).then((resp) => {
        context.commit('setAutomations', resp.data)
      });
    },
    loadAutomationsByClientId(context, clientId) {
      axios(`automation?clientId=${clientId}`).then((resp) => {
        context.commit('setAutomations', resp.data)
      });
    },
    saveAutomation(context, payload) {
      const callback = payload.callback;
      delete payload.callback;
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/automation${finalUrl}`, payload).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Salvo com Sucesso");
          context.dispatch('loadAutomations');
          // se tiver um callback, chama ele
          if (typeof callback == 'function') callback(resp.data);
        }
      });
    },
    deletAutomation(context, payload) {
      const callback = payload.callback;
      delete payload.callback;
      axios.delete(`/automation/${payload.id}`).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Deletado com Sucesso");
          context.dispatch('loadAutomations');
          // se tiver um callback, chama ele
          if (typeof callback == 'function') callback(resp.data);
        }
      });
    },
  },
  getters: {
    getAutomations(state) {
      return state.automations
    },
  },
}