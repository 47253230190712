import axios from 'axios'
export default {
    state: {
        unidades: [],
    },
    mutations: {
        setUnidades(state, payload) {
            state.unidades = payload
        },
    },
    actions: {
        setUnidades(context, payload) {
            context.commit('setUnidades', payload)
        },
        loadUnidades(context) {
            axios(`unidade`).then((resp) => {
                // console.log(resp.data);
                context.commit('setUnidades', resp.data)
            });
        },
        saveUnidade(context, unidade) {
            const metodo = unidade.id ? "put" : "post";
            const finalUrl = unidade.id ? `/${unidade.id}` : "";
            axios[metodo](`/unidade${finalUrl}`, unidade).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Unidade salva com Sucesso");
                    context.dispatch('loadUnidades')
                }
            });
        },
        deletUnidade(context, unidade) {
            axios.delete(`/unidade/${unidade.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Unidade Deletada com Sucesso");
                context.dispatch('loadUnidades');
                }
            });
        },
    },
    getters: {
        getUnidades(state) {
            return state.unidades
        },
        getUnidadeNameById: (state) => (id) => {
            if (!state.unidades) {
                return id
            }
            let gp = state.unidades.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getUnidadesSelect: (state) =>  {
            let gp = []
            state.unidades.forEach(element => {
                gp.push({value: element.id, text: `${element.id} - ${element.name}`})
            });
            return gp 
        },
    },
}