export default {
    state: {
        messageDialog: '',
        showDialog: false,
        valueDialog: 0,
        titleDialog: ''
    },
    mutations: {
        setMessageDialog(state, payload) {
            state.messageDialog = payload;
        },
        setShowDialog(state, payload) {
            state.showDialog = payload;
        },
        setValueDialog(state, payload) {
            state.valueDialog = payload;
        },
        setTitleDialog(state, payload) {
            state.titleDialog = payload;
        },
    },
    actions: {
        setMessageDialog(context, payload) {
            context.commit('setMessageDialog', payload);
        },
        setShowDialog(context, payload) {
            context.commit('setShowDialog', payload);
        },
        setValueDialog(context, payload) {
            context.commit('setValueDialog', payload);
        },
        setTitleDialog(context, payload) {
            context.commit('setTitleDialog', payload);
        },
        setPctDialog(context, payload) {
            context.commit('setValueDialog', parseInt(payload.actual / payload.total * 100));
        },
    },
    getters: {
        getMessageDialog(state) {
            return state.messageDialog;
        },
        getShowDialog(state) {
            return state.showDialog;
        },
        getValueDialog(state) {
            return state.valueDialog;
        },
        getTitleDialog(state) {
            return state.titleDialog;
        },
        getStateLodingDialog(state) {
            return {
                message: state.messageDialog,
                title: state.titleDialog,
                value: state.valueDialog,
                show: state.showDialog,
            }
        },
    }
}