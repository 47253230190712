export default {
    state: {
        windowSize: {
            width:  window.innerWidth,
            height: window.innerHeight,
        },
    },
    mutations: {
        setWindowSize(state, payload) {
            state.windowSize = payload;
        },
    },
    actions: {
        setWindowSize(context, payload) {
            context.commit('setWindowSize', payload);
        },
    },
    getters: {
        getWindowSize(state) {
            return state.windowSize;
        },
    }
}