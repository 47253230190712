<template>
  <div>
    <v-dialog v-model="value" persistent max-width="300">
      <v-card>
        <v-card-title> Selecione uma cor </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Cor"
                hint="Selecione uma cor"
                outlined
                required
                type="color"
                @change="colorSelected"
                :value="color ? color : '#000000'"
                v-model="mycolor"
                :color="mycolor"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clear"> Limpar </v-btn>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogColor",
  props: ["value", "color"],
   data: () => ({
    mycolor: '#000000',
  }),
  methods: {
    close() {
      this.$emit("input", false);
    },
    colorSelected(aham){
        this.$emit("color", aham);
    },
    clear(){
        this.$emit("color", "");
        this.mycolor = '#000000'
    }
  },
};
</script>

<style scoped>
</style>