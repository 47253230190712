import axios from 'axios'
export default {
  state: {
    devices: [],
    devicesAvailable: [],
    device:{}
  },
  mutations: {
    setDevices(state, payload) {
      state.devices = payload
    },
    setDevice(state, payload) {
      state.device = payload
    },
    setDevicesAvailable(state, payload) {
      state.devicesAvailable = payload
    },
  },
  actions: {
    setDevices(context, payload) {
      context.commit('setDevices', payload)
    },
    setDevice(context, payload) {
      context.commit('setDevice', payload)
    },
    setDevicesAvailable(context, payload) {
      context.commit('setDevicesAvailable', payload)
    },
    loadDevices(context) {
      axios(`device`).then((resp) => {
        context.commit('setDevices', resp.data)
      });
    },
    loadDeviceById(context, payload) {
      axios(`device/${payload}`).then((resp) => {
        context.commit('setDevice', resp.data)
      });
    },
    loadDevicesByCLientId(context, clientId) {
      axios(`device?clientId=${clientId}`).then((resp) => {
        context.commit('setDevices', resp.data)
      });
    },
    loadDevicesAvailable(context) {
      axios(`devicesAvailable`).then((resp) => {
        context.commit('setDevicesAvailable', resp.data)
      });
    },
    saveDevice(context, payload) {
      const callback = payload.callback;
      delete payload.callback;
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/device${finalUrl}`, payload).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Salvo com Sucesso");
          context.dispatch('loadDevices');
          // se tiver um callback, chama ele
          if (typeof callback == 'function') callback(resp.data);
        }
      });
    },
    deletDevice(context, payload) {
      const callback = payload.callback;
      delete payload.callback;
      axios.delete(`/device/${payload.id}`).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Deletado com Sucesso");
          context.dispatch('loadDevices');
          // se tiver um callback, chama ele
          if (typeof callback == 'function') callback(resp.data);
        }
      });
    },
  },
  getters: {
    getDevices(state) {
      return state.devices
    },
    getDevice(state) {
      return state.device
    },
    getDevicesAvailable(state) {
      return state.devicesAvailable
    },
    getDeviceNameById: (state) => (id) => {
      if (!state.devices) {
        return id
      }
      let obj = state.devices.find(el =>
        el.id == id
      )
      return obj ? `${obj.id} - ${obj.name}` : ''
    },
    getDeviceTypeNameById: (state) => (id) => {
      if (!state.devicesAvailable) {
        return id
      }
      let obj = state.devicesAvailable.find(el =>
        el.class == id
      )
      return obj ? `${obj.name}` : ''
    },
  },
}