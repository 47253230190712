import axios from 'axios'
export default {
    state: {
        monitoramentos: [],
        moniTypes: [],
        vmsTypes: [],
    },
    mutations: {
        setMonitoramentos(state, payload) {
            state.monitoramentos = payload
        },
        setMonitoramentosTipo(state, payload) {
            state.moniTypes = payload
        },
        setMonitoramentosTipoVms(state, payload) {
            state.vmsTypes = payload
        },
    },
    actions: {
        setMonitoramentos(context, payload) {
            context.commit('setMonitoramentos', payload)
        },
        setMonitoramentosTipo(context, payload) {
            context.commit('setMonitoramentosTipo', payload)
        },
        setMonitoramentosTipoVms(context, payload) {
            context.commit('setMonitoramentosTipoVms', payload)
        },
        loadMonitoramentos(context) {
            axios(`monitoramento`).then((resp) => {
                // console.log(resp.data);
                context.commit('setMonitoramentos', resp.data)
            });
        },
        loadMonitoramentosTipo(context) {
            axios(`monitoramento/software`).then((resp) => {
                // console.log(resp.data);
                context.commit('setMonitoramentosTipo', resp.data)
            });
        },
        loadMonitoramentosTipoVms(context) {
            axios(`monitoramento/softwarevms`).then((resp) => {
                // console.log(resp.data);
                context.commit('setMonitoramentosTipoVms', resp.data)
            });
        },
        saveMonitoramento(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/monitoramento${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Monitoramento salva com Sucesso");
                    context.dispatch('loadMonitoramentos')
                }
            });
        },
        deletMonitoramento(context, monitoramento) {
            axios.delete(`/monitoramento/${monitoramento.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Monitoramento Deletada com Sucesso");
                context.dispatch('loadMonitoramentos');
                }
            });
        },
        deletMonitoramentoIp(context, monitoramento) {
            axios.delete(`/monitoramento/ip/${monitoramento.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "IP do Monitoramento Deletado com Sucesso");
                context.dispatch('loadMonitoramentos');
                }
            });
        },
    },
    getters: {
        getMonitoramentos(state) {
            return state.monitoramentos
        },
        getMonitoramentosTipos(state) {
            return state.moniTypes
        },
        getMonitoramentosTipoVms(state) {
            return state.vmsTypes
        },
        getMonitoramentoTypeById: (state) => (value) => {
            if (!state.moniTypes) {
                return value
            }
            let gp = state.moniTypes.find(g =>
                g.value == value
            )
            return gp ? `${gp.value} - ${gp.text}` : ''
        },
        getMonitoramentoTypeVmsById: (state) => (value) => {
            if (!state.vmsTypes) {
                return value
            }
            let gp = state.vmsTypes.find(g =>
                g.value == value
            )
            return gp ? `${gp.value} - ${gp.text}` : ''
        },
        getMonitoramentosTypeSelect: (state) =>  {
            let gp = []
            state.moniTypes.forEach(element => {
                gp.push({value: element.value, text: `${element.value} - ${element.text}`})
            });
            return gp 
        },
        getMonitoramentosTypeVmsSelect: (state) =>  {
            let gp = []
            state.vmsTypes.forEach(element => {
                gp.push({value: element.value, text: `${element.value} - ${element.text}`})
            });
            return gp 
        },
        getMonitoramentoNameById: (state) => (id) => {
            if (!state.monitoramentos) {
                return id
            }
            let gp = state.monitoramentos.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getMonitoramentoById: (state) => (id) => {
            if (!state.monitoramentos) {
                return id
            }
            let gp = state.monitoramentos.find(g =>
                g.id == id
            )
            return gp || {}
        },
        getMonitoramentosSelect: (state) =>  {
            let gp = []
            state.monitoramentos.forEach(element => {
                gp.push({value: element.id, text: `${element.id} - ${element.name}`})
            });
            return gp 
        },
    },
}