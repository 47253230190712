import axios from 'axios'
export default {
    state: {
        eventosCameras: [],
        eventosTipos:[],
        eventosTiposAvaliables:[],
    },
    mutations: {
        setEventosCameras(state, payload) {
            state.eventosCameras = payload
        },
        setEventosTipos(state, payload) {
            state.eventosTipos = payload
        },
        setEventosTiposAvaliables(state, payload) {
            state.eventosTiposAvaliables = payload
        },
    },
    actions: {
        setEventosTipos(context, payload) {
            context.commit('setEventosTipos', payload)
        },
        setEventosCameras(context, payload) {
            context.commit('setEventosCameras', payload)
        },
        setEventosTiposAvaliables(context, payload) {
            context.commit('setEventosTiposAvaliables', payload)
        },
        loadEventosTipos(context) {
            axios(`tipoEvento`).then((resp) => {
                // console.log(resp.data);
                context.commit('setEventosTipos', resp.data)
            });
        },
        loadEventosCameras(context) {
            axios(`eventoCamera`).then((resp) => {
                context.commit('setEventosCameras', resp.data)
            });
        },
        loadEventosAvailable(context) {
            axios(`eventos/tipos`).then((resp) => {
                context.commit('setEventosTiposAvaliables', resp.data)
            });
        },
        saveEventoTipo(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/tipoEvento${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    if(typeof payload.cb == 'function'){
                        payload.cb()
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Evento salvo com Sucesso");
                    context.dispatch('loadEventosTipos')
                }
            });
        },
        saveEventoCamera(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/eventoCamera${finalUrl}`, payload).then((resp) => {
                if(resp.status < 399){
                    if(typeof payload.cb == 'function'){
                        payload.cb()
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Evento da Camera salvo com Sucesso");
                    context.dispatch('loadEventosCameras')
                }
            });
        },
        // tratamento(context, payload) {
        //     const finalUrl = payload.id ? `/${payload.id}` : "";
        //     axios.put(`/eventos/tratamento${finalUrl}`, payload).then((resp) => {
        //         if(resp.status < 399){
        //             if(typeof payload.cb == 'function'){
        //                 payload.cb()
        //             }
        //             context.dispatch("resetSnack");
        //             context.dispatch("showSuccessSnack", "Evento fechado com Sucesso");
        //             context.dispatch('loadEventos')
        //         }
        //     });
        // },
        deletEventoTipo(context, payload) {
            axios.delete(`/tipoEvento/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Evento Deletado com Sucesso");
                context.dispatch('loadEventosTipos');
                }
            });
        },
        deletEventoCamera(context, payload) {
            axios.delete(`/eventoCamera/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Evento da Camera Deletado com Sucesso");
                context.dispatch('loadEventosCameras');
                }
            });
        },
    },
    getters: {
        getEventosTipos(state) {
            return state.eventosTipos
        },
        getEventosCameras(state) {
            return state.eventosCameras
        },
        getEventosTiposAvaliables(state) {
            return state.eventosTiposAvaliables
        },
        getEventosTiposById: (state) => (id) => {
            if (!state.eventosTipos) {
                return id
            }
            let gp = state.eventosTipos.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getEventosTiposSelect: (state) =>  {
            let gp = []
            state.eventosTipos.forEach(element => {
                gp.push({value: element.id, text: `${element.id} - ${element.name}`})
            });
            return gp 
        },
    },
}