const config = {
  btnEnableCam: {
    name: "Botão Habilitar câmera",
    // text: "Permissão de Back",
    enabled: true
  },
  btnEnableEvtCam: {
    name: "Botão Habilitar eventos da câmera",
    // text: "Permissão de Back",
    enabled: true
  },
  btnViewCam: {
    name: "Botão Ver câmera",
    text: "Ver configurações da câmera",
    enabled: true
  },
  btnEditCam: {
    name: "Botão Editar câmera",
    text: "Editar",
    enabled: true
  },
  btnScheduleCam: {
    name: "Botão Agendamento",
    text: "Agendamento",
    enabled: true
  },
  btnOpenCam: {
    name: "Botão Abrir câmera",
    text: "Abrir câmera",
    enabled: true
  },
  btnConfCam: {
    name: "Botão Configurações Avançadas",
    text: "Configurações avançadas",
    enabled: true
  },
  btnDrawCam: {
    name: "Botão desenho",
    text: "Desenho",
    enabled: true
  },
  btnStartCam: {
    name: "Botão inciar câmera manual",
    text: "Iniciar manual",
    enabled: true
  },
  btnSnapCam: {
    name: "Botão Ver Foto",
    text: "Ver foto (snapshot)",
    enabled: true
  },
}
module.exports = config;