import axios from 'axios'
export default {
    state: {
        grupos: [],
        workGroup: null,
    },
    mutations: {
        setGrupos(state, payload) {
            state.grupos = payload
        },
        setWorkGroup(state, payload) {
            state.workGroup = payload
        },

    },
    actions: {
        setGrupos(context, payload) {
            context.commit('setGrupos', payload)
        },
        loadGrupos(context, payload) {
            let gp = payload ? `?groupId${payload}` : ''
            axios(`grupo${gp}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    element.groupName = context.getters.getGroupNameById(element.id)
                    element.groupFName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit('setGrupos', resp.data)
            });
        },
        loadAllGrupos(context) {
            axios(`grupo/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    element.groupName = context.getters.getGroupNameById(element.id)
                    element.groupFName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit('setGrupos', resp.data)
            });
        },
        saveGrupo(context, grupo) {
            const metodo = grupo.id ? "put" : "post";
            const finalUrl = grupo.id ? `/${grupo.id}` : "";
            axios[metodo](`/grupo${finalUrl}`, grupo).then((resp) => {
                if(resp.status < 399){
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Grupo salvo com Sucesso");
                    context.dispatch('loadGrupos')
                }
            });
        },
        deletGrupo(context, grupo) {
            axios.delete(`/grupo/${grupo.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Grupo Deletado com Sucesso");
                context.dispatch('loadGrupos');
                }
            });
        },
        setWorkGroup(context, payload) {
            context.commit('setWorkGroup', payload)
        },
    },
    getters: {
        workGroup(state) {
            return state.workGroup ? `?groupId=${state.workGroup}` : ''
        },
        getWorkGroup(state) {
            return state.workGroup ? `&groupId=${state.workGroup}` : ''
        },
        getGroups(state) {
            return state.grupos
        },
        getGroupNameById: (state) => (id) => {
            if (!state.grupos) {
                return id
            }
            let gp = state.grupos.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getGroupSelect: (state) =>  {
            let gp = []
            state.grupos.forEach(element => {
                gp.push({value: element.id, text: `${element.id} - ${element.name}`})
            });
            return gp 
        },
        showGroups(state){
            return state.grupos.length > 1
        },
        getGrupoHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Grupo Pai", value: "groupFName" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}