<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title>
          Grupos
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getAll"> Atualizar Dados </v-btn>
        </v-card-title>

        <v-form>
          <v-container>
            <v-row>
              <v-checkbox label="Ativo" v-model="group.enabled"></v-checkbox>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="group.name"
                  :counter="255"
                  label="Nome"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="group.groupId"
                  :items="getGroupSelect"
                  label="Grupo Pai"
                >
                </v-autocomplete>
                <!-- <v-text-field
                  v-model.number="group.groupId"
                  :counter="255"
                  label="Grupo Pai"
                  required
                  type="number"
                ></v-text-field> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="group.description"
                  rows="2"
                  label="Descrição"
                  hint="Digite uma descrição da Permissão"
                  :counter="255"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="clear">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save(group)">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-row>
        <v-col>
          <Table
            :headers="getGrupoHeaders"
            :items="getGroups"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event)"
          />
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "./TablePerm";
import {mapGetters} from 'vuex';
export default {
  name: "groups",
  components: {
    Table,
  },
  computed:{
    ...mapGetters(['getGroups', 'getGroupSelect','getGroupNameById','getGrupoHeaders'])
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      group: {
        id: null,
        enabled: true,
      },
      groups: [],
      recursos: [],
      niveis: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Descrição", value: "description" },
        { text: "Ativo", value: "ativo" },
        { text: "Cadastrado", value: "criado" },
        { text: "Modificado", value: "atualizado" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    get() {
      // this.$http("grupo").then((resp) => {
      //   resp.data.forEach((element) => {
      //     element.ativo = element.enabled ? "SIM" : "NÃO";
      //     element.criado = new Date(element.createdAt).toLocaleString();
      //     element.atualizado = new Date(element.updatedAt).toLocaleString();
      //   });
      //   this.groups = resp.data;
      // });
      this.$store.dispatch('loadGrupos');
    },

    getNiveis() {
      this.$http("grupo").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${
            element.description
          }) ${element.superUser ? "SUPERUSUÁRIO" : ""}`;
          element.value = element.id;
        });
        this.niveis = resp.data;
      });
    },
    save(group) {
      // const metodo = group.id ? "put" : "post";
      // const finalUrl = group.id ? `/${group.id}` : "";
      // this.$http[metodo](`/grupo${finalUrl}`, group).then(() => {
      //   this.get();
      //   this.clear();
      //   (this.snackbar = true), (this.text = "Salvo com Sucesso");
      // });
      this.$store.dispatch('saveGrupo', group);
    },
    edit(rec) {
      this.group = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(group) {
      // this.$http.delete(`/grupo/${group}`).then(() => {
      //   this.get();
      //   (this.snackbar = true), (this.text = "Deletado com Sucesso");
      // });
      this.$store.dispatch('deletGrupo', group);
    },
    getAll() {
      this.get();
      // this.getNiveis();
    },
    clear() {
      this.group = {
        id: null,
        enabled: true,
        nvRecursoId: null,
        nvAcessoId: null,
        get: true,
        getParam: true,
        put: true,
        putParam: true,
        post: true,
        postParam: true,
        delete: true,
        deleteParam: true,
        description: "",
      };
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>

<style>
</style>