import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import snackbar from './modules/snackbar'
import grupo from './modules/grupos'
import unidades from './modules/unidades'
import monitoramento from './modules/monitoramento'
import cameras from './modules/cameras'
import clientes from './modules/clientes'
import eventos from './modules/eventos'
import configEventos from './modules/configEventos'
import menu from './modules/menu'
import dialogLoading from './modules/dialogLoading'
import windowSize from './modules/windowSize'
import manuais from './modules/manuais'
import permFront from './modules/permFront'
import importCam from './modules/importCam'
import socketIO from './modules/socketIO'
import device from './modules/device'
import automation from './modules/automation'
import cameraAction from './modules/cameraAction'
import proxy from './modules/proxy'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {},
        token: null,
        imgToken: null,
        logged: false,
        myUser: {},
        targetUrl: null,
    },
    mutations: {
        setPermissoes(state, payload) {
            state.permissoes = payload
        },
        setImgToken(state, payload) {
            state.imgToken = payload
            localStorage.setItem('heimdallimg', payload)
        },
        setLogged(state, payload) {
            state.logged = payload
        },
        setUser(state, payload) {
            state.user = payload
            state.logged = true
        },
        setMyUser(state, payload) {
            state.myUser = payload
        },
        setToken(state, payload) {
            state.logged = true
            state.token = payload
            axios.defaults.headers.common['Authorization'] = `${payload}`
            localStorage.setItem('heimdall', payload)
            let data = payload.split('.')
            state.user = JSON.parse(atob(data[1]))
        },
        setToken2(state, payload) {
            state.token = payload
            axios.defaults.headers.common['Authorization'] = `${payload}`
        },
        destroyUser(state, payload) {
            state.logged = false
            if (payload)
                state.token = null
            state.user = {}
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('heimdall')
            router.push('/login')
        },
        setTargetUrl(state, payload) {
            // usado para definir a rota de redirecionamento que o usuário tentou acessar antes de logar
            if (state.targetUrl == null && !state.logged) {
                state.targetUrl = payload
            }
            // state.targetUrl = payload
        },
    },
    actions: {
        setMyUser(context, payload) {
            context.commit('setMyUser', payload);
        },
        setImgToken(context, payload) {
            context.commit('setImgToken', payload);
        },
        setUser(context, payload) {
            context.commit('setUser', payload);
        },
        logOff(context) {
            this.state.logged = false
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('heimdall')
            context.commit('setLogged', false)
            context.commit('destroyUser', null)
        },
        setLogged(context, payload) {
            context.commit('setLogged', payload)
        },
        loadMyUser(context) {
            axios(`/myuser`).then((resp) => {
                context.dispatch('setMyUser', resp.data);
                context.dispatch('setUser', resp.data);
            });
        },
        setTargetUrl(context, payload) {
            context.commit('setTargetUrl', payload)
        },
    },
    getters: {
        getUsuario(state) {
            return state.user
        },
        getToken(state) {
            return state.token
        },
        getImgToken(state) {
            return state.imgToken
        },
        getImgTokenQuery(state) {
            return `?token=${state.imgToken}`
        },
        getMyUser(state) {
            return state.myUser
        },
        getLogged(state) {
            return state.logged
        },
        getDefaultRoute(state) {
            return state.user.route
        },
        getTargetUrl(state) {
            return state.targetUrl;
        },
    },
    modules: {
        snackbar,
        grupo,
        unidades,
        monitoramento,
        cameras,
        clientes,
        eventos,
        configEventos,
        menu,
        dialogLoading,
        windowSize,
        manuais,
        permFront,
        importCam,
        socketIO,
        device,
        automation,
        cameraAction,
        proxy,
    }
})