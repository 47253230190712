<template>
  <v-app>
    <Menu />
    <AppBar v-if="$store.getters.getWindowSize.height > 390" />
    <v-main>
      <v-container fluid>
        <span class="bg"></span>
        <router-view></router-view>
        <v-snackbar
          v-model="snck.snackbar"
          :color="snck.color"
          :timeout="snck.timeout"
        >
          {{ snck.text }} 
          <v-btn icon v-if="snck.closable" @click="snck.snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Menu from "./components/MenuBanco.vue";
// import Menu from "./components/Menu";
import AppBar from "./components/AppBar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AppBar,
    Menu,
  },
  computed: { ...mapGetters(["snackbar","getLogged"]) },
  watch: {
    snackbar() {
      this.snck = this.snackbar;
    },
  },
  data: () => ({
    snck: {},
    title: "Heimdall",
  }),
  methods: {
    onResize() {
      let windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      this.$store.dispatch("setWindowSize", windowSize);
    },
  },
  created() {
    document.title = this.title;
    this.onResize();
    if (window.location.pathname == "/cliente") {
      const urlParams = new URLSearchParams(window.location.search);
      const hash = urlParams.get("hash");
      return this.$router.push({ path: "/cliente" , query:{hash}});
    }
  },
};
</script>
<style>
/* .bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: url("./assets/background.jpg") no-repeat center center;
  background-size: cover;
  opacity: 0.5;
  transform: scale(1.1);
} */
.menu {
  background: #005f36 !important;
  color: rgb(223, 223, 223) !important;
}
html {
  overflow-y: auto;
}
body{
  background: #121212;
}
</style>